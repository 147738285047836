import React from "react";
import Styles from "./TrainingOps.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const TrainingOps = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Training Ops</h1>
          <p className={'paddingTop-3'}>Training Operations (TrainingOps) involves a strategic approach to managing and optimizing the development of consultants and staff across various technical and business domains. This includes a thorough needs assessment to identify gaps in skills and performance based on current requirements and strategic objectives. Training programs are then designed to address these needs, encompassing a range of areas from technical skills to industry knowledge. The development of tailored training content, such as workshops, seminars, and online courses, ensures that the programs are relevant and effective.  
          </p>
          <p className={'paddingTop-3'}>
          The delivery of these training programs utilizes diverse methods, including in-person sessions, virtual training, and blended learning approaches, to accommodate different learning styles and logistical constraints. Expert trainers facilitate these sessions to ensure interactive and practical learning experiences. Continuous evaluation and feedback collection help assess the effectiveness of the training, allowing for improvements and refinements based on participant input and performance metrics. 
          </p>  
            
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
      <p className={'paddingTop-3'}>
          Key areas of TrainingOps include <b>Generative AI training</b>, which equips consultants with skills in AI model development and application, and <b>Cloud Computing training</b> that covers cloud platforms and infrastructure management. <b>CI/CD Deployment training</b> focuses on automating software development processes, while <b>Managed Services training</b> prepares staff to manage outsourced services. <b>Full Stack Development training</b> provides comprehensive skills in both frontend and backend technologies, and <b>Container & EKS training</b> emphasizes containerization and Kubernetes management. <b>Cost Management training</b> is essential for optimizing budgets and resources, while <b>Sales & Marketing training</b> enhances strategies for client acquisition and retention. Finally, <b>IP Protection training</b> covers the safeguarding of intellectual property and understanding relevant legal frameworks. 
          </p>
      <p className={'paddingTop-3'} >
      To effectively manage these training initiatives, consultancies should align programs with business goals, engage industry experts, and keep content updated with current trends. Regular assessment of training outcomes and continuous improvement based on feedback are crucial for maintaining the relevance and impact of the training. By integrating these elements, consultancies can enhance their team’s capabilities, improve service delivery, and maintain a competitive edge in a dynamic market.
          </p>
      </div>
    </div>
  );
};

export default TrainingOps;
