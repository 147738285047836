
import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./VentureCapital.module.css"
import image from "../../assets/img/Group 1321313995.png"

const VentureCapital = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.LeftCont}>
        <h1>Venture capital</h1>
        <p className='paddingTop-3'>Venture capital is essential for fuelling innovation and growth across industries by providing early-stage funding to promising startups. In the technology and AI sectors, VC invests in startups developing cutting-edge technologies such as artificial intelligence, machine learning, and advanced software solutions. These investments support innovations in AI applications across various fields including healthcare, finance, and cybersecurity, as well as infrastructure like data analytics platforms and development tools. VC also backs advancements in robotics, natural language processing, and automation to enhance productivity and address complex challenges.</p>
        <p className='paddingTop-3'>Venture capital is crucial for nurturing innovation and advancing various sectors by funding early-stage companies with high growth potential. This support drives technological advancements, enhances industry practices, and shapes the future of multiple fields.
        </p>
      </div>
      <div className={Styles?.rightCont}>
        <img src={image} />
      </div>
    </div>
  )
}

export default VentureCapital;