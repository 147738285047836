import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./DataSoftware.module.css"
import image from "../../../assets/img/Group 1321313995.png"
import Button from '../../Elements/Button/Button';


const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course provides an overview of intellectual property (IP) rights, including patents, trademarks, and copyrights.",
        "Who Can Do: Ideal for entrepreneurs, inventors, and business leaders.",
        "Benefits: Understand IP rights, learn to protect your innovations, and navigate IP laws.",
        "Certification: Upon completion, you will receive a certificate in IP Protection."
      ]
    },
  ];

const DataSoftware = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.afterBannerContent}>
          <h1>Data Software Lab</h1>
          <p className={'paddingTop-3'}>
          Data software labs have emerged as essential tools in the modern data-driven landscape, allowing businesses to refine and utilize data more effectively than ever. These labs provide a dedicated environment for data science activities, enabling organizations to explore complex datasets, develop insights, and drive strategic decisions. By separating data labs from traditional data storage systems, such as data lakes or warehouses, companies gain the capacity to manage multiple data science projects concurrently, scale operations flexibly with cloud technology, and generate reliable outputs that enhance business intelligence and decision-making.
          </p>
          <p className={'paddingTop-3'}>
          The integration of cloud technologies has further advanced the capabilities of data labs, offering enhanced versatility, remote access, and secure data management. With cloud-based solutions, data labs can expand according to business needs, allowing for greater agility and cost-efficiency. Despite the challenges of setting up and maintaining a data lab—such as sourcing skilled personnel, ensuring software compatibility, and safeguarding data security— innovative data insights and robust analytics make data labs a crucial asset for businesses aiming to lead in their industries. 
          </p>  
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
    </div>
    )
}

export default DataSoftware;