import React from "react";
import Styles from "./TechOpsMenu.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const TechOps = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Tech Ops</h1>
          <p className={'paddingTop-3'}>In technology operations (TechOps), several specialized practices help manage and optimize IT systems, applications, and infrastructure. <b>CloudOps</b> focuses on managing cloud computing resources and services efficiently, automating cloud resource management, and ensuring performance, security, and cost control. <b>DataOps</b> enhances data management and analytics by automating and optimizing the data lifecycle, integrating data engineering and quality practices to accelerate data delivery and improve decision-making.  
          </p>
          <p className={'paddingTop-3'}>
          <b>DevOps</b> merges development and operations teams to streamline software delivery and improve application quality through automation, continuous integration and delivery (CI/CD), and infrastructure as code (IaC). Meanwhile, <b>AI & ML Ops</b> is dedicated to deploying and managing artificial intelligence and machine learning models in production, overseeing model lifecycle management, data preparation, and performance monitoring. 
          </p>  
          <p className={'paddingTop-3'}>
          <b>Site Reliability Engineering (SRE)</b> aims to ensure the reliability, availability, and performance of software systems by implementing service level objectives (SLOs), managing incidents, and planning capacity, blending engineering practices with IT operations. <b>Cost Optimization</b> focuses on managing and reducing operational expenses while maintaining performance, involving techniques such as optimizing resource usage, right-sizing infrastructure, and implementing effective budgeting practices. 
          </p>  
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
      <p>
      Integrating these practices involves aligning them with overall business goals, leveraging automation to enhance efficiency, continuously monitoring and optimizing performance and costs, and fostering collaboration among development, operations, data, and AI teams. By doing so, organizations can improve operational efficiency, service reliability, and cost management.
          </p>
      </div>
    </div>
  );
};

export default TechOps;
