import logo from "./logo.svg";
import "./App.css";
// import AppRouter from './Components/Routes/Routes';
// import Header from './Components/Header/Header';
// import Footer from './Components/Footer/Footer';
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/400-italic.css";
import "./assets/css/bo-branding.css";
import AppRouter from "./Components/Routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { APICall } from "./Components/backend/apiConfig";
import { api_config } from "./Components/backend/endpoints";
import { getID } from "./UtilHelper";

export const MyContext = React.createContext();

function App() {
  const initialOptions = {
    clientId:
      "AXW0gSDrRaqKwMkrPVj1Ak-ynCo4D9rb-sX32f7-65SjIsUG7PugI2x9cbqDqKTSraFj87qr_Z4xWr5C",
    currency: "USD",
    intent: "capture",
  };
  const [context, setContext] = useState({});
  const [purchased, setPurchased] = useState([]);
  const [filteredPurchaseList, setFilteredPurchaseList] = useState([]);

  useEffect(() => {
    const fetchPurchasedCourses = async () => {
      try {
        const response = await APICall(api_config.getPurchasedCourses, {
          user_id: getID("userId"),
        });
        console.log(response);
        setPurchased(response.data.data);
        setFilteredPurchaseList(
          response.data.data.map(({ sections, ...rest }) => rest)
        );
      } catch (error) {
        console.error("Error fetching purchased courses:", error);
      }
    };

    fetchPurchasedCourses();
  }, []);

  useEffect(() => {
    console.log(filteredPurchaseList);
  });

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className="App appMainClass">
        {/* <Header /> */}
        <ToastContainer />

        <MyContext.Provider
          value={{ context, setContext, filteredPurchaseList }}
        >
          <AppRouter />
        </MyContext.Provider>
        {/* <Footer /> */}
      </div>
    </PayPalScriptProvider>
  );
}

export default App;
