import { useNavigate } from "react-router-dom";
import { label_config } from "../../Config/labelConfig";
import Input from "../Elements/Input/Input";
import styles from "./Signup.module.css";
import Button from "../Elements/Button/Button";
import { useFormik } from "formik";
import { SignupValidations } from "../backend/Validations/Schema";
import { CallAPI } from "../backend/apiConfig";
import { api_config } from "../backend/endpoints";
import { encrypt, loginResponse } from "../../UtilHelper";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../Context/Context";
import { toast } from "react-toastify";
const BOSignup = () => {
  const navigate = useNavigate();
  const { context, setContext } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      if (context?.isLoggedIn) {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  }, [context]);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        first_name: "",
        last_name: "",
        bus_email: "",
        password: "",
        confirmPassword: "",
      },
      validationSchema: SignupValidations,
      onSubmit: (values) => {
        const updatedReqData = {
          ...values,
          loginType: "system",
          roles: "bo_user",
        };
        setLoading(true);
        CallAPI(api_config?.user_register, updatedReqData).then((res) => {
          setLoading(false);
          if (res?.status?.code === 200) {
            loginResponse(res?.data, res?.session);
            setContext((prev) => ({
              ...prev,
              userDetails: encrypt(JSON.stringify(res?.data)),
              isLoggedIn: true,
            }));
            navigate("/");
          } else {
            toast.error(res?.status?.message);
          }
        });
      },
    });

  return (
    <div className={`${styles?.container} bo-shadow bo-border-radius`}>
      <div className={styles?.header}>
        <h5>{label_config?.lbl_register_now}</h5>
      </div>
      <form className={styles?.loginForm} onSubmit={handleSubmit}>
        <div className={`${styles?.body} marginTop-3`}>
          <div className="form-group marginTop-2">
            <Input
              error={errors["first_name"]}
              touched={touched["first_name"]}
              onBlur={handleBlur}
              onChange={handleChange}
              placeHolder="First name"
              className=""
              fieldName={"first_name"}
              value={values["first_name"]}
            />
          </div>
          <div className="form-group marginTop-4">
            <Input
              error={errors["last_name"]}
              touched={touched["last_name"]}
              onBlur={handleBlur}
              onChange={handleChange}
              placeHolder="Last name"
              fieldName={"last_name"}
              value={values["last_name"]}
            />
          </div>
          <div className="form-group marginTop-4">
            <Input
              error={errors["bus_email"]}
              onBlur={handleBlur}
              touched={touched["bus_email"]}
              onChange={handleChange}
              placeHolder="Email"
              fieldName={"bus_email"}
              value={values["bus_email"]}
            />
          </div>
          <div className="form-group marginTop-4">
            <Input
              error={errors["password"]}
              touched={touched["password"]}
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              placeHolder="Password"
              fieldName={"password"}
              value={values["password"]}
            />
          </div>
          <div className="form-group marginTop-4">
            <Input
              error={errors["confirmPassword"]}
              onBlur={handleBlur}
              touched={touched["confirmPassword"]}
              onChange={handleChange}
              type="password"
              placeHolder="Confirm password"
              fieldName={"confirmPassword"}
              value={values["confirmPassword"]}
            />
          </div>
        </div>
        <div className={`${styles?.footer} marginTop-4`}>
          <Button
            type="submit"
            label="Register"
            className={`${styles?.loginButton} marginTop-2 `}
            parentClass={styles?.buttonContainer}
            loading={loading}
          />
          <h6 className={`marginTop-2 ${styles?.bottomActionLabel}`}>
            {label_config?.lbl_already_account}
            <span
              className="hyperLink"
              onClick={() => {
                navigate("/login");
              }}
            >
              {" "}
              {label_config?.lbl_login_now}
            </span>
          </h6>
        </div>
      </form>
    </div>
  );
};

export default BOSignup;
