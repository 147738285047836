import React from "react";
import Styles from "./FinOps.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const FinOps = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Fin Ops</h1>
          <p className={'paddingTop-3'}>Financial Operations (FinOps) in the context of consulting and investment involve several key mechanisms that can significantly impact a company’s growth and financial strategy.  
          </p>
          <p className={'paddingTop-3'}>
          <b>Venture Capital (VC)</b> provides funding to early-stage or rapidly growing companies in exchange for equity. This funding helps startups scale quickly by supporting product development, market expansion, and talent acquisition. VC investors often play an active role in guiding the company and providing strategic support.
          </p>
          <p className={'paddingTop-3'}>
          <b>Initial Public Offering (IPO)</b> is the process where a company offers its shares to the public for the first time, raising capital from public investors. Going public through an IPO gives a company access to a large pool of funds, raises its profile, and allows early investors to partially cash out. It requires strict regulatory compliance and detailed financial disclosures.
          </p>
          <p className={'paddingTop-3'}>
          <b>Angel Investment</b> involves high-net-worth individuals investing in early-stage companies, often in exchange for equity or convertible debt. Besides providing capital, angel investors offer valuable mentorship and advice, helping startups get off the ground before seeking larger venture capital.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
        <p className={'paddingTop-3'}>
        <b>Mergers and Acquisitions (M&A)</b> are transactions where companies combine or one company acquires another. M&A can lead to growth, increased market share, or diversification. Successful M&A requires thorough valuation, negotiation, and integration planning to achieve strategic goals.
        </p>
        <p className='paddingTop-3'>
        <b>Special Purpose Acquisition Company (SPAC)</b> is a shell company that raises capital through an IPO with the goal of acquiring an existing company. This approach provides a quicker, often simpler path to going public compared to a traditional IPO. Once the SPAC raises funds, it seeks out and acquires a target company, effectively bringing it public. 
        </p>
        <p className='paddingTop-3'>
        To effectively use these financial strategies, companies should align them with their long-term goals, develop detailed plans for each financial step, seek advice from financial and legal experts, and manage risks carefully. By leveraging VC, IPOs, angel investments, M&A, and SPACs, companies can enhance growth, improve market position, and optimize financial performance. 
        </p>
      </div>
    </div>
  );
};

export default FinOps;
