import React from "react";
import Styles from "./CloudOps.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const CloudOps = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>CloudOps</h1>
          <p className={'paddingTop-3'}>
          CloudOps focuses on managing, optimizing, and securing cloud environments for reliable and scalable operations. Our Cloud Operations (CloudOps) services optimize your cloud infrastructure, ensuring efficient management, performance, and scalability of IT services across multi-cloud, hybrid, and edge environments. By leveraging best practices and advanced analytics, we provide insights to enhance visibility, control resources, and run services efficiently, replacing traditional network operations centers with cloud-based monitoring and management.
          </p>
          <p className={'paddingTop-3'}>
          We guide organizations through the cloud migration process, helping to develop tailored strategies that include all stakeholders and emphasize security. Our approach incorporates automation to accelerate workflows, enhance agility, and reduce disruptions, ensuring the cloud environment is secure and cost-effective. We also offer training to equip your teams with the necessary skills for effective cloud management.
          </p>
          <p className={'paddingTop-3'}>
          Our services are designed to support continuous operations, streamline processes, and improve collaboration between development and IT teams. By integrating CloudOps with existing DevOps practices, we help businesses achieve faster, more efficient delivery of services, reduced operational costs, and enhanced productivity, all while maintaining a secure and compliant cloud environment.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
        
      </div>

    
    </div>
  );
};

export default CloudOps;
