import React from "react";
import Styles from "./Button.module.css";
import { BOLoading } from "..";

const Button = (props) => {
  const {
    type = "button",
    label = "",
    className = "",
    parentClass = "",
    onClick,
    disabled = false,
    children,
    loading = false
  } = props;

  return (
    <div className={`${Styles?.boButton} ${Styles?.parentContainer} ${parentClass}`}>
      <button
        type={type}
        className={`btn ${Styles?.buttonClass} ${className}`}
        onClick={onClick}
        disabled={loading || disabled}
      >
        {children || label}{loading && <BOLoading style={{ marginLeft: "5px" }} />}
      </button>
    </div>
  );
};

export default Button;
