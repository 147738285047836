import React from "react";
import Styles from "./BusinessOps.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const BusinessOps = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Business Ops</h1>
          <p className={'paddingTop-3'}>As a boutique consulting company, in business operations, we focus mainly on Revenue growth, Sales Automation, Business Transformation & Profitability. 
          </p>
          <p className={'paddingTop-3'}>
          To drive <b>revenue growth</b>, we focus on understanding market trends, acquiring new clients, expanding services, and adopting effective pricing strategies. Analyzing market needs helps identify opportunities for new revenue streams. Targeted marketing and strategic pricing models, such as value-based or tiered pricing, can attract and retain clients, boosting overall revenue.
          </p>
          <p className={'paddingTop-3'}>
          <b>Sales automation</b> is key to streamlining client interactions and improving efficiency. Implementing Customer Relationship Management (CRM) systems helps track leads, manage sales pipelines, and automate follow-ups. Automating tasks like lead qualification and proposal generation, combined with data-driven insights and lead nurturing through marketing automation, enhances the sales process.
          </p>
          <p className={'paddingTop-3'}>
          <b>Business transformation</b> involves strategic planning and process optimization to align with long-term goals. Companies should focus on innovating and improving efficiency while managing change effectively. Digital technologies can be leveraged to enhance operations and client engagement, ensuring the company remains competitive and agile.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
        <p className={'paddingTop-3'}>
        For increased <b>profitability</b>, it's essential to manage costs and analyze profit margins. Regularly reviewing expenses and identifying cost-saving opportunities, along with diversifying revenue streams, helps stabilize cash flow. Improving operational efficiency and reducing waste also contribute to higher profit margins.
        </p>
        <p className='paddingTop-3'>
        Integrating these focus areas requires alignment of goals and metrics, cross-functional teamwork, and a culture of continuous improvement. Technology should support seamless data integration across functions, enabling effective execution of strategies for growth, automation, transformation, and profitability. 
        </p>
      </div>
    </div>
  );
};

export default BusinessOps;
