import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./FullStack.module.css"
import image from "../../../assets/img/Group 1321313995.png"

const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course covers full-stack development, including front-end, back-end, and database technologies.",
        "Who Can Do: Ideal for aspiring web developers and software engineers.",
        "Benefits: Gain a comprehensive understanding of web development, and learn to build complete web applications.",
        "Certification: Upon completion, you will receive a certificate in FullStack Development"
      ]
    },
  ];

const FullStack = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Full Stack</h1>
          <p className={'paddingTop-3'}>
            <b>Full Stack Development</b> involves building all layers of a web application or software, from designing the user interface (UI) and user experience (UX) to managing server-side logic and database interactions. Full Stack developers are skilled in both front-end and back-end technologies, allowing them to handle every aspect of the development lifecycle.
          </p>
          <p className={'paddingTop-3'}>
          <b>Our training</b> in full stack development covers the full spectrum of skills needed to excel in the field. <b>Introduction to Full Stack Development</b> provides foundational knowledge, including front-end basics (HTML, CSS, JavaScript) and back-end fundamentals (server-side technologies and databases). <b>Front-End Development</b> focuses on HTML & CSS, JavaScript frameworks (React, Angular, Vue.js), and responsive design. <b>Back-End Development</b> explores server-side languages (Node.js, Python, PHP), database management (SQL and NoSQL), and API design. <b>DevOps & Deployment</b> covers version control (Git), CI/CD pipelines, and cloud deployment (AWS, Azure, Google Cloud). <b>Advanced Topics</b> include performance optimization, security best practices, and project management. 
          </p>  
            
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
      <p className={'paddingTop-3'}>
      <b>Our training programs</b> offer practical experience and comprehensive skills in both front-end and back-end development, preparing you for complex projects and a successful career in the tech industry. Starting or looking to enhance your skills, our courses will equip you with the expertise needed to thrive in a competitive job market.
      </p>
      </div>
    </div>
    )
}

export default FullStack;