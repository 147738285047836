import React from "react";
import Styles from "./Design.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const Design = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Design Team Lease </h1>
          <p className={'paddingTop-3'}>
          Leasing a design team to organizations involves assembling and providing skilled professionals for specific projects or periods. The process starts with understanding the client's needs by defining the project scope, objectives, and design requirements. This involves determining the size and skill set of the team needed and the duration of the engagement. Once the requirements are clear, we recruit and prepare a team of designers, ensuring they have the necessary experience and are familiar with the client's goals and environment.
          </p>
          <p className={'paddingTop-3'}>
          Next, we draft a detailed service agreement outlining the scope of work, payment terms, and other key aspects, while addressing legal considerations like intellectual property and confidentiality. The design team is then deployed to the client organization, where they integrate with existing teams and start working on the project. We provide ongoing management to ensure alignment with project goals, address any issues that arise, and facilitate effective communication between the team and the client.
          </p>
          <p className={'paddingTop-3'}>
          Throughout the project, we monitor the team’s performance and progress, making adjustments based on client feedback and evolving requirements. Upon project completion, we conduct a final review to assess success and gather feedback, providing any additional support required post-engagement. This approach ensures the client receives tailored design solutions and a seamless experience with the leased team.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
        
      </div>

    


    </div>
  );
};

export default Design;
