import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./GenAi.module.css"
import image from "../../../assets/img/Group 1321313995.png"

const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course provides a comprehensive introduction to the field of Artificial Intelligence (AI). It covers fundamental concepts, techniques, and applications.",
        "Who Can Do: Ideal for students, IT professionals, and enthusiasts looking to understand AI and its applications.",
        "Benefits: Gain a solid understanding of AI, enhance your problem-solving skills, and prepare for advanced AI topics.",
        "Certification: Upon completion, you will receive a certificate in General AI Training"
      ]
    },
    
  ];

const GenAi = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Generative AI </h1>
          <p className={'paddingTop-3'}>Generative AI is a rapidly advancing field that focuses on creating models capable of generating new content, from text and images to more complex outputs. In today’s world, Generative AI is transforming various industries by driving innovation, enhancing creativity, and streamlining processes. Its applications range from automating content creation to providing sophisticated data analysis, making it a crucial technology for staying competitive and forward-thinking. 
          </p>
          <p className={'paddingTop-3'}>
          Our Generative AI training offers a range of courses to suit various skill levels and interests. For beginners, we provide an  <b>Introduction to Generative AI</b> and a <b>Foundational Course on Generative AI</b>, covering the basics of AI, neural networks, and Large Language Models (LLMs). Intermediate learners can dive deeper with  <b>Advanced Generative AI Techniques</b>, which explores complex models and their applications. Business professionals can benefit from <b>Generative AI in Business</b>, focusing on practical AI strategies for business. The <b>Hands-on Workshop: Building Generative AI Models</b> offers practical experience in creating and testing models. We also offer specialized courses like <b>Building Applications with LLMs, Generative AI in Everyday Practice, Introduction to Generative AI APIs</b>, and <b>Exploring Deep Generative Models</b>. Advanced options include <b>AI Innovation and Application</b> and <b>Generative AI on Cloud Platforms</b>, providing in-depth insights and skills for more advanced users. 
          </p>  
            
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
      <p className={'paddingTop-3'}>
      By participating in our training, you’ll gain essential skills and practical insights to effectively implement Generative AI in your work. Our courses are designed to provide hands-on experience, expert instruction, and tailored content that aligns with your specific needs and goals. This approach ensures that you not only understand the theoretical aspects but also apply AI technologies to drive innovation and productivity in your professional environment.
      </p>
      </div>
    </div>
    )
}

export default GenAi;