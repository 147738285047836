import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./iMetapro.module.css"
import image from "../../../assets/img/Group 1321313995.png"
import Button from '../../Elements/Button/Button';


const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course provides an overview of intellectual property (IP) rights, including patents, trademarks, and copyrights.",
        "Who Can Do: Ideal for entrepreneurs, inventors, and business leaders.",
        "Benefits: Understand IP rights, learn to protect your innovations, and navigate IP laws.",
        "Certification: Upon completion, you will receive a certificate in IP Protection."
      ]
    },
  ];

const MetaPro = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.afterBannerContent}>
          <h1>iMetapro</h1>
          <p className={'paddingTop-3'}>
          iMeta is a pioneering Metaverse Experience Management Platform designed to bridge the gap between physical and virtual realities. By integrating cutting-edge technologies such as Virtual Reality (VR), Augmented Reality (AR), and Mixed Reality (MR), iMeta enables businesses to create and manage immersive, 3D virtual environments where users can interact, explore, and engage with digital content seamlessly and intuitively. Whether for virtual training, remote collaboration, or interactive events, iMeta provides a robust framework for developing dynamic metaverse experiences that enhance user engagement and drive innovation across various sectors. 
          </p>
          <p className={'paddingTop-3'}>
          The platform's versatility allows it to serve as a hub for metaverse applications, from virtual product demonstrations and digital twin simulations to immersive educational programs and virtual social spaces. iMeta supports the creation of custom avatars, virtual worlds, and interactive scenarios, making it an essential tool for companies looking to leverage the evolving landscape of the metaverse. As businesses continue to explore the potential of these immersive technologies, iMeta stands out as a comprehensive solution for managing and optimizing virtual experiences that connect users in meaningful and impactful ways. 
          </p>  
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
    </div>
    )
}

export default MetaPro;