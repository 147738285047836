import React, { useContext, useEffect, useState } from "react";
import CourseCard from "../Elements/CourseCard/CourseCard";
import Styles from "./AllCourses.module.css";
import { APICall } from "../backend/apiConfig";
import { api_config } from "../backend/endpoints";
import { toast } from "react-toastify";
import { ShimmerPostList } from "react-shimmer-effects";
import { MyContext } from "../../App";
import NoCoursefound from "../NoCourseFound/NoCoursefound";

const AllCourses = () => {
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { filteredPurchaseList } = useContext(MyContext);
  const [processedCourseList, setProcessedCourseList] = useState([]);

  const fetchCourseList = async () => {
    try {
      const res = await APICall(api_config.fetchCoursesList);
      if (res?.status.code === 200) {
        const { data } = res;
        if (data) {
          setCourseList(data);
        }
      } else {
        toast.error(res?.status?.message, {
          pauseOnHover: false,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourseList();
  }, []);

  useEffect(() => {
    if (courseList.length > 0 && filteredPurchaseList.length > 0) {
      const coursesWithPurchaseStatus = courseList.map((course) => ({
        ...course,
        isPurchased: filteredPurchaseList.some(
          (purchasedCourse) => purchasedCourse.id === course.id
        ),
      }));
      setProcessedCourseList(coursesWithPurchaseStatus);
    } else {
      setProcessedCourseList(courseList);
    }
  }, [filteredPurchaseList, courseList]);

  return (
    <div className={Styles.MainContainer}>
      {loading ? (
        <div className={Styles.shimmerwrapper}>
          <ShimmerPostList
            postStyle="STYLE_FOUR"
            card
            col={3}
            row={2}
            imageWidth={500}
            gap={30}
          />
        </div>
      ) : processedCourseList.length > 0 ? (
        <div className={Styles.Container}>
          {processedCourseList.map((value, index) => {
            const { userDetails } = value;
            return (
              <CourseCard
                key={index}
                imageUrl={value?.thumbnail}
                title={value.course_title}
                duration={value.video_length}
                author={`${userDetails?.first_name || ""} ${
                  userDetails?.last_name || ""
                }`}
                course={value}
                price={value.actual_price}
                profile={userDetails?.profile_url}
                isPurchased={value.isPurchased} // Pass the isPurchased prop to CourseCard
              />
            );
          })}
        </div>
      ) : (
        <NoCoursefound />
      )}
    </div>
  );
};

export default AllCourses;
