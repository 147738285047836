import axios from "axios";
import { toast } from "react-toastify";
import { getUserData } from "../../UtilHelper";
// Create an Axios instance with a base URL
const axiosInstance = axios.create({
    // baseURL: 'https://alpha.tecdemy.com:9011',
    // baseURL: 'http://34.229.82.103:9011/',
    // baseURL: 'https://alpha.tecdemy.com/',
    // baseURL: 'https://apialpha.tecdemy.com/',
    baseURL: 'http://localhost:9011/',
    headers: { "x-api-key": "" }
});

export const CallAPI = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, { ...data, org: "bo" });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const APICall = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response.data
  } catch (error) {
    console.error(error);
  }

};
