import styles from "./ForgotPassword.module.css"
const ForgotPassword = (props) => {
    return (

        <dialog className={`${styles?.dialogMain} bo-box-shadow bo-border-radius`} id="forgotPasswordWidget">
            <div className={styles?.dialogHeader}>
                <h5>Recover you password</h5>
            </div>
            <div className={styles?.dialogBody}>

            </div>
            <div className={styles?.dialogFooter}>

            </div>

        </dialog>


    )
}
export default ForgotPassword;