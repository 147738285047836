import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./SolvSoftware.module.css"
import image from "../../../assets/img/Group 1321313995.png"
import Button from '../../Elements/Button/Button';


const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course provides an overview of intellectual property (IP) rights, including patents, trademarks, and copyrights.",
        "Who Can Do: Ideal for entrepreneurs, inventors, and business leaders.",
        "Benefits: Understand IP rights, learn to protect your innovations, and navigate IP laws.",
        "Certification: Upon completion, you will receive a certificate in IP Protection."
      ]
    },
  ];

const SolvSoftware = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.afterBannerContent}>
          <h1>Solv Software</h1>
          <p className={'paddingTop-3'}>
          Solv Software is a cloud-based solution designed to simplify the management of healthcare practices. It offers essential features like patient scheduling, medical billing, and electronic health records (EHR), helping medical professionals run their practices more efficiently. The software also includes tools for online appointment scheduling, reputation management, and telemedicine, allowing healthcare providers to enhance patient experiences and improve their online presence. 
          </p>
          <p className={'paddingTop-3'}>
          In addition to its core functionalities, Solv integrates seamlessly with major EHR systems like Epic and Cerner, making it a versatile choice for various medical practices. The platform supports online patient registration and personalized profile management, enabling easier appointment scheduling and better patient engagement. With Solv, healthcare providers can optimize their operations and deliver a higher level of care to their patients.
          </p>  
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
    </div>
    )
}

export default SolvSoftware;