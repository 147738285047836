import React from "react";
import Styles from "./CostOptimization.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const CostOptimization = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Cost Optimization</h1>
          <p className={'paddingTop-3'}>
          Our focus on cost optimization provides comprehensive strategies aimed at reducing expenses, enhancing operational efficiency, and improving overall financial health. We begin by conducting a thorough analysis of your business operations to identify areas where costs can be reduced without compromising quality or performance. This includes streamlining processes, renegotiating contracts with suppliers, and adopting more cost-effective technologies. By aligning our approach with your business goals, we ensure that every dollar spent contributes directly to your bottom line.
          </p>
          <p className={'paddingTop-3'}>
          We assist you in implementing cost-saving measures by developing a tailored strategy that includes budget management, resource allocation, and process automation. Our consultants work closely with your team to identify inefficiencies and provide solutions that minimize waste and maximize productivity. This could involve consolidating services, reducing redundancies, or optimizing resource usage to ensure operations run as efficiently as possible.
          </p>
          <p className={'paddingTop-3'}>
          In addition to immediate cost reductions, we focus on long-term financial sustainability by helping you establish robust cost control mechanisms. This includes setting up monitoring systems to track expenses, implementing continuous improvement programs, and training your team on best practices for cost management. Our goal is to reduce costs and create a cost-conscious culture within your organization, ensuring that you maintain financial discipline and achieve sustained profitability.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
        
      </div>

    
    </div>
  );
};

export default CostOptimization;
