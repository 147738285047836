import React from "react";
import Styles from "./Departments.module.css";
import Button from "../../Elements/Button/Button";
import { DEPARTMENTCARDS, DEPARTMENTIMAGES } from "../../../Config/Config";
import Card from "../Card/Card";
import image from "../../../assets/img/Frame 1321314004.png"

const Departments = () => {
  return (
    <div className={`${Styles?.Container} paddingTop-5`}>
      <h1>Our Departments</h1>
      <div className={Styles?.cardcontainer}>
      {DEPARTMENTCARDS.map((card, index) => (
        <Card key={index} image={card.icon} title={card.title} imageClass={Styles?.cardImage}/>
      ))}
      {/* {DEPARTMENTIMAGES.map((image, index) => (
        <img key={index} src={image.src} alt={image.title} className={Styles?.Image}/>
      ))} */}
      </div>
      <div className={Styles?.SixPack}>
      </div>
      {/* <Button label="Load More" className="btn" /> */}
    </div>
  );
};

export default Departments;