import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./AngelInvestments.module.css"
import image from "../../assets/img/Group 1321313995.png"

const AngelInvestments = () => {
    return (
        <div className={`${Styles?.Container} marginTop-5`}>
    <div className={Styles?.LeftCont}>
    <h1>Angel Investments</h1>
          <p className={`paddingTop-3`}>Angel investors provide early-stage funding to startups in exchange for equity or convertible debt. Unlike traditional investors like banks or venture capital firms, angel investors use their own money and are often high-net-worth individuals with industry experience. They typically invest in startups at a critical early stage, helping cover initial costs such as product development and marketing. Angel investors are known for their higher risk tolerance and may offer valuable mentorship and industry connections to help startups grow.</p>
          <p className={`paddingTop-3`}>In addition to providing capital, angel investors build diversified portfolios by investing in multiple startups across various sectors, which helps manage risk and increase the chances of successful returns. They look to eventually exit their investments through mergers, acquisitions, or IPOs. Overall, angel investors play a significant role in fostering innovation, creating jobs, and driving economic growth by supporting new technologies and entrepreneurial ventures.</p>
    </div>
    <div className={Styles?.rightCont}>
      <img src={image}/>
    </div>
  </div>
    )
}

export default AngelInvestments;