import React from "react";
import Styles from "./PlatformOps.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const Platform = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Platform Ops</h1>
          <p className={'paddingTop-3'}>Platform Operations (PlatformOps) involve the management and optimization of various technology platforms that support both consulting services and internal operations. Key aspects of PlatformOps include platform management, performance monitoring, integration, security, user support, cost management, and scalability.  
          </p>
          <p className={'paddingTop-3'}>
          PlatformOps is a replacement for DevOps. In DevOps, Application development, CICD, Infrastructure provisioning, clustering, monitoring, security are handled by different departments which results in excessive division of responsibilities & therefore increases disjointness. In case if you have multiple DevOps teams, they may use different tools for all these processes. This results in additional operational complexities. 
          </p>
          <p className={'paddingTop-3'}>
          However, PlatformOps focuses on creating and maintaining a unified platform to manage infrastructure across the entire organization which results in saving cost & saving time. This also improves in better resource utilization, better manageability, better configuration, enhanced scalability, and overall system reliability. By providing a standardized environment, development processes & operational efficiency can be improved significantly. An effective platform can also improve overall infrastructure management and governance.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
        <p className='paddingTop-3'>
        For specific platforms such as <b>Tecdemy, Trainingdemy, iMetapro, Data Software Lab, Outgen AI, Marcom USA, Solv Software, Pulse Predict, and MobileApp Factory</b>, PlatformOps include managing their unique features and functions. This involves optimizing e-learning modules, managing training programs, handling data integration, supporting software development, overseeing AI solutions, managing marketing communications, and handling app development, among other tasks.
        </p>
        <p className='paddingTop-3'>
        Effective management of these platforms requires aligning them with business objectives, ensuring seamless integration, and continuously improving based on performance metrics and user feedback. This approach enhances operational efficiency, supports business growth, and delivers high-quality services. 
        </p>
      </div>
    </div>
  );
};

export default Platform;
