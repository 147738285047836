import React from "react";
import Styles from "./DataOps.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const DataOps = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>DataOps</h1>
          <p className={'paddingTop-3'}>
          We specialize in leveraging DataOps to help businesses unlock the full potential of their data. DataOps is not just a methodology but a comprehensive framework that blends technical practices, workflows, and cultural shifts to accelerate innovation and ensure reliable data delivery. We work closely with your data teams to break down silos between data producers and consumers, enabling rapid, high-quality insights that drive better decision-making and faster business outcomes.
          </p>
          <p className={'paddingTop-3'}>
          Our approach to DataOps is rooted in the principles of continuous integration and continuous deployment (CI/CD), mirroring the success of DevOps but tailored for data engineering and analytics. We assist organizations in implementing automation-first strategies, ensuring that data pipelines are not only efficient but also resilient. By focusing on data observability, we help you monitor and maintain the health of your data, reducing downtime and ensuring that your data assets are always accurate, reliable, and ready for analysis.
          </p>
          <p className={'paddingTop-3'}>
          Partnering with us means you gain access to a team of experts who understand the complexities of data environments and the importance of agile, scalable solutions. Whether you’re looking to optimize existing data processes or build new ones from the ground up, we provide the guidance and tools needed to foster a robust DataOps culture within your organization, leading to sustained innovation and a competitive edge in the market.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
        
      </div>

    
    </div>
  );
};

export default DataOps;
