import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./Ipo.module.css"
import image from "../../assets/img/Group 1321313995.png"

const Ipo = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.LeftCont}>
        <h1>Initial Public Offering (IPO)</h1>
        <p className='paddingTop-3'>An Initial Public Offering (IPO) marks a major shift for a company as it transitions from privately held to publicly traded. We play a crucial role in this process by helping companies prepare strategically. We assist in evaluating the company's readiness, understanding its market value, and aligning its business goals with investor expectations. We refine the company's business model, financial, and growth strategies to make them appealing to potential investors.
        </p>
        <p className='paddingTop-3'>
        We also guide companies through the complex regulatory landscape by preparing important documentation such as the registration statement and prospectus. This involves detailed financial disclosures, risk factors, and information about the company's operations and management. Additionally, we support financial audits to ensure compliance with accounting standards and assist in valuing the company and setting an appropriate share price based on monetary performance and market conditions.
        </p>
        <p className='paddingTop-3'>
        After the IPO, we continue to offer support by advising on investor relations, regulatory compliance, and ongoing reporting requirements. We help manage risks, including market volatility and operational challenges, and assist in strategies for maintaining shareholder confidence. Overall, we help in ensuring a smooth IPO process and assist companies to succeed in the public market.
        </p>
      </div>
      <div className={Styles?.rightCont}>
        <img src={image} />
      </div>
    </div>
  )
}

export default Ipo;