import React, { useEffect, useState } from "react";
import CourseCard from "../Elements/CourseCard/CourseCard";
import Image from "../../assets/img/Learn-DevOps-in-90-Min.jpg";
import cloudfoundation from "../../assets/img/Copy-of-Learn-DevOps-in-90-Min.jpg";
import AIandCloud from "../../assets/img/Copy-of-Learn-DevOps-in-90-Min-2.jpg";
import AIandCognitive from "../../assets/img/Copy-of-Learn-DevOps-in-90-Min-4.jpg";
import AIResearch from "../../assets/img/Copy-of-Learn-DevOps-in-90-Min-5.jpg";
import AWSCloudFormmation from "../../assets/img/Copy-of-Learn-DevOps-in-90-Min-9.jpg";
import Architecting from "../../assets/img/Copy-of-Learn-DevOps-in-90-Min-10.jpg";
import Styles from "./MyCourses.module.css";
import { APICall, CallAPI } from "../backend/apiConfig";
import { api_config } from "../backend/endpoints";
import { toast } from "react-toastify";
import { ShimmerPostList } from "react-shimmer-effects";
import { NavLink } from "react-router-dom";
import NoCoursefound from "../NoCourseFound/NoCoursefound";
import { getID } from "../../UtilHelper";

const MyCourses = () => {
  const [courseList, setCourseList] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchCourseList = () => {
    try {
      APICall(api_config.getPurchasedCourses, {
        user_id: getID("userId"),
      }).then((res) => {
        console.log(res);
        if (res?.status.code === 200) {
          const { data } = res.data;
          if (data) {
            setCourseList(data);
            setLoading(false);
          }
          return;
        }
        toast.error(res?.status?.message, {
          pauseOnHover: false,
        });
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCourseList();
  }, []);
  return (
    <div className={Styles.MainContainer}>
      {loading ? (
        <div className={Styles?.shimmerwrapper}>
          <ShimmerPostList
            postStyle="STYLE_FOUR"
            card
            col={3}
            row={2}
            imageWidth={500}
            gap={30}
          />
        </div>
      ) : courseList.length > 0 ? (
        <div className={Styles?.Container}>
          {courseList.map((value, index) => {
            const { userDetails } = value;
            return (
              <CourseCard
                key={index}
                imageUrl={value?.thumbnail}
                title={value.course_title}
                duration={value.video_length}
                author={`${userDetails?.first_name || ""} ${
                  userDetails?.last_name || ""
                }`}
                course={value}
                price={value.actual_price}
                profile={userDetails?.profile_url}
              />
            );
          })}
        </div>
      ) : (
        <NoCoursefound />
      )}
    </div>
  );
};

export default MyCourses;
