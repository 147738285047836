import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./IPProtection.module.css"
import image from "../../../assets/img/Group 1321313995.png"

const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course provides an overview of intellectual property (IP) rights, including patents, trademarks, and copyrights.",
        "Who Can Do: Ideal for entrepreneurs, inventors, and business leaders.",
        "Benefits: Understand IP rights, learn to protect your innovations, and navigate IP laws.",
        "Certification: Upon completion, you will receive a certificate in IP Protection."
      ]
    },
  ];

const IPProtection = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>IP Protection</h1>
          <p className={'paddingTop-3'}>
          <b>Intellectual Property (IP) Protection</b> involves safeguarding creations of the mind, such as inventions, literary and artistic works, designs, and trademarks. In today's innovation-driven economy, protecting IP is crucial as it ensures that creators and businesses can secure their rights over their intellectual assets. This protection helps prevent unauthorized use by others, maintaining the legal recognition of your creations and supporting your competitive edge. Effective IP protection is essential for avoiding financial losses and reputational damage from competitors copying or misusing your work.  
          </p>
          <p className={'paddingTop-3'}>
          Our IP Protection training covers a comprehensive range of topics to equip you with the essential skills for protecting your intellectual property. <b>"Introduction to Intellectual Property Law"</b> offers an overview of patents, copyrights, trademarks, and trade secrets. <b>"Patents: From Application to Protection"</b> guides you through the patent process, including filing and managing patent rights. <b>"Protecting Trade Secrets and Confidential Information"</b> covers strategies for safeguarding sensitive information. <b>"Trademarks and Brand Protection"</b> focuses on trademark registration and enforcement. <b>"Copyright Essentials and Digital Content Management"</b> addresses copyright protection for digital and software content.   
          </p>  
            
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
      <p >
      "IP Strategies for Startups and SMEs" provides practical advice for protecting intellectual assets affordably. <b>"International Intellectual Property Protection"</b> explores global IP protection strategies, and <b>"Intellectual Property in Emerging Technologies"</b> examines IP challenges in fields like AI and blockchain.
      </p>
      <p  className={'paddingTop-3'}>
      <b>Our training</b> is designed to enhance your understanding of IP law and protection mechanisms, helping you make informed decisions about safeguarding your intellectual assets. You'll learn strategies to mitigate risks of IP theft and infringement, strengthen your competitive edge, and promote a culture of innovation within your organization. Additionally, the training prepares you to navigate international markets confidently, ensuring your IP rights are upheld globally. Invest in our IP Protection training to secure your intellectual assets and support long-term success.
      </p>
      </div>
    </div>
    )
}

export default IPProtection;