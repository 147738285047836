import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./SpecialAcquisition.module.css"
import image from "../../assets/img/Group 1321313995.png"

const SpecialAcquisition = () => {
    return (
        <div className={`${Styles?.Container} marginTop-5`}>
    <div className={Styles?.LeftCont}>
    <h1>Special Purpose Acquisition Companies (SPACs)</h1>
          <p className={`paddingTop-3`}>Special Purpose Acquisition Companies (SPACs) have become a prominent feature in today’s market, offering an alternative route for companies to go public. A SPAC is essentially a shell company that raises capital through an initial public offering (IPO) with the sole purpose of acquiring or merging with a private company, thereby making it public without the traditional IPO process.</p>
          <p className={`paddingTop-3`}>We can significantly enhance the opportunities presented by Special Purpose Acquisition Companies (SPACs) by guiding their formation and strategic planning. We assist in structuring the SPAC, defining its focus, and crafting a compelling business plan to attract investors. This involves market analysis and identifying industry trends to ensure that the SPAC’s investment strategy is well-aligned with potential opportunities.</p>
          <p className={`paddingTop-3`}>Once the SPAC is established, we help identify suitable acquisition targets, conduct thorough due diligence, and negotiate deal terms. We also support the integration of the acquired company, ensuring a smooth transition and alignment with the SPAC’s strategic goals. Our expertise in structuring deals, managing post-acquisition processes, and implementing growth strategies is crucial for maximizing value and achieving successful outcomes for the SPAC and its investors.</p>
    </div>
    <div className={Styles?.rightCont}>
      <img src={image}/>
    </div>
  </div>
    )
}

export default SpecialAcquisition;