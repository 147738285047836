import styles from './AboutUs.module.css'
import aboutUsImage from "../../assets/img/Group 1321314000 (1).png"

const AboutUs = () => {
    return <div className={styles.aboutUsContainer}>
        <div className={styles.leftContainer}>
        <h1 className={styles.pageTitle}>About Us</h1>
            <p>Business Optima is a Boutique Business Consulting Company specializing in Financial Consulting, Technology Consulting, Solution Acceleration, Platform Consulting, Training & Transformation Services. We manage client partnerships and project teams to drive transformations. Contact us at sales@businessoptima.com for all your business consulting needs, and booking@businessoptima.com for your training needs.</p>
            <p className='paddingTop-3'>Our experienced consultants bring strategic thinking, industry knowledge, and the latest technology to help your business needs. Our expertise is spread across the finance and technology domains. Our team has helped businesses in the US, India, and the GCC region thrive by leveraging the power of Cloud computing & Managed Services. Our products & platforms use AI & machine learning extensively.</p>
        </div>
        <div className={`${styles.rightContainer} padding-3`}>
            <img src={aboutUsImage} alt="About Us Image" />
        </div>

    </div>
}

export default AboutUs;

