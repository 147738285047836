import React from "react";
import Styles from "./Devops.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const DevopsTeam = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>DevOps Team Lease</h1>
          <p className={'paddingTop-3'}>
          We can lease a DevOps team to organizations by following a streamlined approach to address specific needs and deliver effective solutions. First, we conduct a thorough needs assessment to understand the client's current infrastructure and DevOps requirements. This involves identifying the key goals, such as improving deployment frequency or automating workflows. Based on this, we develop a DevOps strategy to address these needs and enhance the client's development and operations processes.
          </p>
          <p className={'paddingTop-3'}>
          Next, we select a team of skilled DevOps professionals, which may include engineers, cloud specialists, and automation experts. They manage the onboarding process, ensuring the team is familiar with the client’s systems and workflows. This preparation helps the team integrate smoothly into the client's environment and start working effectively, whether on-site or remotely.
          </p>
          <p className={'paddingTop-3'}>
          During the deployment phase, we oversee the team's activities, including setting up CI/CD pipelines, automating infrastructure, and optimizing deployment processes. We handle project management, track progress, and ensure alignment with the client’s objectives, while also facilitating communication and resolving any issues that arise.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
        
      </div>

      <div className={Styles?.afterBannerContent}>
        <p className={'paddingTop-3'}>
        Finally, we provide ongoing support and optimization throughout the project. They manage feedback, make necessary adjustments, and ensure that the DevOps solutions meet the client’s expectations. We also offer flexibility in scaling the team based on evolving needs, ensuring that resources are efficiently managed and aligned with the client’s goals. This approach ensures the client benefits from a well-managed and adaptable DevOps team.
        </p>
      </div>
    </div>
  );
};

export default DevopsTeam;
