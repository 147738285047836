export const api_config = {
  user_login: "/api/business/bologin",
  user_register: "/api/business/boSignup",
  fetchCoursesList: "/api/business/fetchCoursesList",
  getwishlist: "/api/user/getWishList_v1",
  getcartlist: "/api/user/getCartItems_v1",
  addtowishlist: "/api/user/addToWishlist_v1",
  addtocartlist: "/api/user/addToCart_v1",
  removefromwishlist: "/api/user/removeFromWishlist_v1",
  removefromCart: "/api/user/removeFromCart_v1",
  createOrder: "/api/user/my-server/create-paypal-order",
  captureOrder: "/api/user/my-server/capture-paypal-order",
  addToPurchase: "/api/user/addPurchasedByUserv1",
  getPurchasedCourses: "/api/user/getPurchasedByUserv1",
  createServiceRequest: "/api/business/create_service_request"
};
