import { useNavigate } from "react-router-dom";
import { label_config } from "../../Config/labelConfig";
import Input from "../Elements/Input/Input";
import styles from "./Login.module.css";
import Button from "../Elements/Button/Button";
import { useFormik } from "formik";
import { LoginPageValidations } from "../backend/Validations/Schema";
import React, { createElement, useContext, useEffect, useState } from "react";
import { CallAPI } from "../backend/apiConfig";
import { api_config } from "../backend/endpoints";
import { toast } from "react-toastify";
import { encrypt, loginResponse, setID } from "../../UtilHelper";
import { AppContext } from "../Context/Context";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

const BOLogin = ({ setToken }) => {
  const navigate = useNavigate();
  const { context, setContext } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      if (context?.isLoggedIn) {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  }, [context]);
  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        bus_email: "",
        password: "",
      },
      validationSchema: LoginPageValidations,
      onSubmit: (values) => {
        console.log(values);
        setLoading(true);
        CallAPI(api_config?.user_login, {
          ...values,
          loginType: "system",
        }).then((res) => {
          setLoading(false);
          if (res?.status?.code === 200) {
            loginResponse(res?.data, res?.session);
            setToken(res?.session?.token);
            setContext((prev) => ({
              ...prev,
              userDetails: encrypt(JSON.stringify(res?.data)),
              isLoggedIn: true,
            }));
            navigate("/");
          } else {
            toast.error(res?.status?.message);
          }
        });
      },
    });

  const handleOpenForgotPassword = () => {
    try {
      const forgotElement = document.getElementById("forgotPasswordWidget");
      if (forgotElement) {
        forgotElement.showModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`${styles?.container} bo-shadow bo-border-radius`}>
      <div className={styles?.header}>
        <h5>{label_config?.lbl_welcome}</h5>
      </div>
      <form className={styles?.loginForm} onSubmit={handleSubmit}>
        <div className={`${styles?.body} marginTop-3`}>
          <div className="form-group marginTop-2">
            <Input
              error={errors["bus_email"]}
              onBlur={handleBlur}
              touched={touched["bus_email"]}
              onChange={handleChange}
              placeHolder="Email address"
              className=""
              fieldName={"bus_email"}
              value={values["bus_email"]}
            />
          </div>
          <div className="form-group marginTop-4">
            <Input
              error={errors["password"]}
              onBlur={handleBlur}
              touched={touched["password"]}
              onChange={handleChange}
              type="password"
              placeHolder="Password"
              fieldName={"password"}
              value={values["password"]}
            />
          </div>
        </div>
        <div className={`${styles?.footer} marginTop-3`}>
          <div className={styles?.groupActions}>
            <Button
              type="submit"
              label="Sign in"
              className={`${styles?.loginButton} marginTop-2 `}
              parentClass={styles?.buttonContainer}
              loading={loading}
            />
            <h6 className={`marginTop-2 ${styles?.bottomActionLabel}`}>
              <span className="hyperLink" onClick={handleOpenForgotPassword}>
                {" "}
                {label_config?.lbl_forgot_password}
              </span>
            </h6>
          </div>

          <h6 className={`marginTop-2 ${styles?.bottomActionLabel}`}>
            {label_config?.lbl_do_you_have_acc}
            <span
              className="hyperLink"
              onClick={() => {
                navigate("/signup");
              }}
            >
              {" "}
              {label_config?.lbl_register_now}
            </span>
          </h6>
        </div>
      </form>

      <ForgotPassword />
    </div>
  );
};

export default BOLogin;
