import React from "react";
import Styles from "./Business.module.css";
import image from "../../assets/img/Group 1321313995.png"

const businessAcceleratorServices = [
  {
    title: "Business Accelerator",
    description:
      "We assist industries with digital transformation through a holistic approach encompassing strategic planning, technology integration, customer-centric solutions, employee engagement, cybersecurity measures, and continuous optimization. This multifaceted approach enables organizations to thrive in the digital era, fostering innovation, efficiency, and sustained growth.",
  },
  {
    title: "We help you transform",
    description:
      "Transforming a business is a holistic process that involves not only adopting new technologies but also fostering a culture of innovation, prioritizing customer needs, and ensuring the organization is agile and adaptable. Successful business transformation positions a company to thrive in an ever-changing business landscape.",
  },
  {
    title: "We help you grow",
    description:
      "Growth in the industry involves leveraging internal resources, developing new products or services, and expanding market share through strategic initiatives. Growth is often considered sustainable and indicative of a company’s ability to innovate, capture market demand, and enhance overall competitiveness. It involves incremental improvements, customer retention strategies, and the development of internal capabilities. Companies achieving growth demonstrate resilience and adaptability, fostering a strong foundation for long-term success in the evolving business landscape.",
  },
  {
    title: "Integrating and implementing AI systems",
    description:
      "Businesses can effectively harness the power of AI to drive innovation, improve efficiency, and gain a competitive edge in the corporate landscape. Prioritize ethical considerations in AI implementation, ensuring fairness, transparency, and accountability. Develop customized AI solutions tailored to the corporation’s unique requirements",
  },
];

const Business = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
    <div className={Styles?.LeftCont}>
    <h1>Business Transformation</h1>
    {businessAcceleratorServices.map((section, index) => (
        <div key={index} className={`${Styles.Section} marginTop-5`}>
          <h4>{section.title}</h4>
          <p>{section.description}</p>
        </div>
      ))}
    </div>
    <div className={Styles?.rightCont}>
      <img src={image}/>
    </div>
  </div>
  );
};

export default Business;
