import React from "react";
import Styles from "./TeamLease.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const TeamLease = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Team + Lease</h1>
          <p className={'paddingTop-3'}>Team leasing is a strategic service offered by us to provide specialized teams or professionals to organizations on a temporary or contract basis. This model enables businesses to access expert talent for specific projects or ongoing functions without committing to permanent hires. TeamLease provides staffing solutions and workforce management across diverse industries. Our services span temporary and permanent staffing, payroll and compliance management, skill development, and comprehensive workforce management. We cater to various sectors, including manufacturing, retail, and IT, focusing on flexible, scalable staffing solutions that meet evolving labor market demands.  
          </p>
          <p className={'paddingTop-3'}>
          When we lease a team to an organization, several steps are involved. First, a client needs assessment is conducted to understand the project requirements and scope, involving detailed discussions to identify necessary skills and engagement duration. Following this, we assemble a team with the required expertise and prepare them by briefing them on project objectives and client expectations. A formal contract is then established, outlining the project scope, deliverables, fees, and terms, including confidentiality and intellectual property rights. The leased team is introduced to the client’s organization, integrated into their workflows, and provided with necessary resources and information. 
          </p>  
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
      <p>
          Throughout the engagement, we monitor the team’s performance, offer support, and conduct regular check-ins to ensure project success and address any issues. Feedback is collected from the client to assess the team’s performance and make improvements for future engagements. The engagement concludes with a wrap-up meeting, and the team transitions either back to the consultancy or onto other projects.
          </p>
      <p className={'paddingTop-3'}>
          Various types of team leases cater to different needs. Project-based teams are assembled for specific projects with defined goals and timelines, such as software development or marketing campaigns. Managed services teams provide ongoing support for functions like IT, HR, or customer service, managed by the consultancy but integrated into the client’s operations. Technical teams consist of IT experts or engineers addressing technical needs such as infrastructure management or cybersecurity. Consulting teams offer strategic advice in areas like business strategy or financial planning. Training teams conduct programs on leadership or technical skills, while sales and marketing teams focus on enhancing sales performance and marketing initiatives. Creative teams handle design, branding, and content creation, and financial and legal teams assist with planning, auditing, and compliance. Human resources teams manage HR functions like recruitment and employee relations, and innovation and R&D teams work on developing new products or technologies to support business growth.
          </p>
          <p className={'paddingTop-3'}>
          Effective team leasing requires alignment with organizational goals, smooth integration of teams, and regular evaluation of performance and impact. This approach provides flexible access to specialized expertise tailored to specific needs, enhancing operational efficiency and project outcomes.
          </p>
      </div>
    </div>
  );
};

export default TeamLease;
