import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./Trainingdemy.module.css"
import image from "../../../assets/img/Group 1321313995.png"
import Button from '../../Elements/Button/Button';


const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course provides an overview of intellectual property (IP) rights, including patents, trademarks, and copyrights.",
        "Who Can Do: Ideal for entrepreneurs, inventors, and business leaders.",
        "Benefits: Understand IP rights, learn to protect your innovations, and navigate IP laws.",
        "Certification: Upon completion, you will receive a certificate in IP Protection."
      ]
    },
  ];

const Training = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.afterBannerContent}>
          <h1>Trainingdemy</h1>
          <p className={'paddingTop-3'}>
          Trainingdemy is an online platform for all your training needs. Trainingdemy would encompass a broad range of services providing comprehensive training and educational solutions. Trainingdemy provides a platform for users to access and complete courses, including video lectures, interactive modules, and assessments. Trainingdemy has developed and curated a diverse library of training courses across various fields, including technology, business, healthcare, and personal development. Trainingdemy designs and delivers training programs for businesses to upskill their employees and meet organizational goals.  
          </p>
          <p className={'paddingTop-3'}>
          We provide detailed reports and insights on learner performance, course effectiveness, and training outcomes. We ensure that training materials and courses are accessible on various devices, including smartphones and tablets. We collaborate with industry experts and educational institutions to offer high-quality content and certifications. We also offer training solutions to meet diverse needs and preferences. We provide support for continuous learning and skill development to help users stay competitive in their fields. We exclusively help organizations improve employee skills, compliance, and overall productivity.   
          </p>  
      <p className={'paddingTop-3'}>
      Interested? Please click the following link to get started 
      </p>
      <p className={'paddingTop-3'}>
      <a href='https://www.trainingdemy.com/'>
      <Button>
        Trainingdemy 
      </Button>
      </a>
      </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
    </div>
    )
}

export default Training;