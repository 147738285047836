import React from "react";
import Styles from "./NoCourseFound.module.css";
import nocourse from "../../assets/img/images.png";

const NoCoursefound = () => {
  return (
    <div className={Styles?.Container}>
      <img src={nocourse} alt="noCoursesFound" className={Styles?.image} />
      <div className={Styles?.Courses}>No Courses Found</div>
    </div>
  );
};

export default NoCoursefound;
