import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./Mobileapp.module.css"
import image from "../../../assets/img/Group 1321313995.png"
import Button from '../../Elements/Button/Button';


const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course provides an overview of intellectual property (IP) rights, including patents, trademarks, and copyrights.",
        "Who Can Do: Ideal for entrepreneurs, inventors, and business leaders.",
        "Benefits: Understand IP rights, learn to protect your innovations, and navigate IP laws.",
        "Certification: Upon completion, you will receive a certificate in IP Protection."
      ]
    },
  ];

const MobileApp = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.afterBannerContent}>
          <h1>Mobile App Factory
          </h1>
          <p className={'paddingTop-3'}>
          A Mobile App Factory is a structured and systematic approach to developing mobile applications efficiently and consistently. It operates as a centralized platform or framework that enables organizations to streamline the app development process by standardizing tools, methodologies, and best practices. This model facilitates collaboration among multiple project teams, allowing them to build, test, and deploy a variety of applications concurrently while maintaining high quality and alignment with business objectives. 
          </p>
          <p className={'paddingTop-3'}>
          The key benefits of a Mobile App Factory include reduced development time, cost efficiency, and the ability to scale resources based on project demands. By utilizing reusable components and standardized processes, organizations can accelerate time-to-market for their apps and ensure a consistent user experience across different platforms and devices. Additionally, this approach supports better governance and control over the application lifecycle, from conception to decommissioning, enabling continuous improvement and adaptability in a rapidly evolving technological landscape.  
          </p>  
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
    </div>
    )
}

export default MobileApp;