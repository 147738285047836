import React from 'react';
import Styles from './Marquee.module.css'; 

const Marquee = ({ images, direction = 'left' }) => {
  return (
    <div className={Styles?.marqueeContainer}>
      <div className={`${Styles?.marquee} ${direction === 'right' ? Styles?.marqueeReverse : ''}`}>
        {/* <div className="marquee-line"> */}
          {images.map((image, index) => (
            <img key={index} src={image.link} alt={`marquee-img-${index}`} className={`${Styles?.marqueeImg}`} />
          ))}
          {images.map((image, index) => (
          <img key={`duplicate-${index}`} src={image.link} alt={`marquee-img-${index}`} className={Styles?.marqueeImg} />
        ))}
        {/* </div> */}
        </div>
      </div>
  );
};

export default Marquee;