import * as CryptoJS from "crypto-js";
import { ENCRYPTION_KEY } from "./Config/siteConfig";
import { APICall } from "./Components/backend/apiConfig";
import { api_config } from "./Components/backend/endpoints";
import { toast } from "react-toastify";
export const getUserData = () => {
  const userToken = localStorage.getItem("userToken");
  const userData = localStorage.getItem("userData");
  // if(userToken &&userToken!=null){
  //   var usertoken = decrypt(userToken)
  //   return { usertoken };
  // }if(userData && userData!=null){
  //   var userdata  =JSON.parse( decrypt(userData))
  //   return { userdata };
  // }
  if (userData && userData != null && userToken && userToken != null) {
    return { userToken, userData };
  } else {
    return false;
  }
};

export const encrypt = (plainText = "") => {
  try {
    const cipherText = CryptoJS.AES.encrypt(
      plainText,
      ENCRYPTION_KEY
    ).toString();
    return cipherText;
  } catch (error) {
    console.error(error);
  }
};

export const decrypt = (cipherText = "") => {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, ENCRYPTION_KEY);
    const plainText = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(plainText);
  } catch (error) {
    console.error(error);
  }
};

export const setID = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error(error);
  }
};

export const getID = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.error(error);
  }
};

export const removeID = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
};

export const loginResponse = (userData, session) => {
  try {
    setID("userId", userData?.id);
    setID("userDetails", encrypt(JSON.stringify(userData)));
    setID("isLoggedIn", "1");
    setID("userToken", session?.token);
  } catch (error) {
    console.error(error);
  }
};

export const clearUserData = () => {
  localStorage.clear();
};

// Function to add to cart
export const addToCart = async (
  userDetails,
  course,
  actualPrice,
  setCart,
  setCartLoading
) => {
  setCartLoading(true);
  try {
    const response = await APICall(api_config.addtocartlist, {
      user_id: getID("userId"),
      course_id: course.id,
      actual_price: actualPrice,
    });
    console.log(response);
    if (response?.status?.code === 200) {
      setCart(true);
      toast.success("Added to Cart", {
        pauseOnHover: false,
      });
    } else {
      toast.error(response?.status?.message || "Course already added to Cart", {
        pauseOnHover: false,
      });
    }
  } catch (error) {
    toast.error("An error occurred while adding to cartlist", {
      pauseOnHover: false,
    });
  } finally {
    setCartLoading(false);
  }
};

// Function to remove from cart
export const removeFromCart = async (course, setCartLoading) => {
  setCartLoading(true);
  try {
    const response = await APICall(api_config.removefromCart, {
      user_id: getID("userId"),
      course_id: course.id,
    });
    if (response?.status?.code === 200) {
      toast.success("Removed from Cart", {
        pauseOnHover: false,
      });
    } else {
      toast.error(response?.status?.message || "Failed to remove from Cart", {
        pauseOnHover: false,
      });
    }
  } catch (error) {
    toast.error("An error occurred while removing from cartlist", {
      pauseOnHover: false,
    });
  } finally {
    setCartLoading(false);
    window.location.reload();
  }
};

// Function to add to wishlist
export const addToWishlist = async (
  userDetails,
  course,
  setBookmark,
  setLoading
) => {
  setLoading(true);
  try {
    const response = await APICall(api_config.addtowishlist, {
      user_id: getID("userId"),
      course_id: course.id,
    });
    if (response?.status?.code === 200) {
      setBookmark(true);
      toast.success("Added to wishlist!", {
        pauseOnHover: false,
      });
    } else {
      toast.error(
        response?.status?.message || "Course already added to wishlist",
        {
          pauseOnHover: false,
        }
      );
    }
  } catch (error) {
    toast.error("An error occurred while adding to wishlist", {
      pauseOnHover: false,
    });
  } finally {
    setLoading(false);
  }
};

// Function to remove from wishlist
export const removeFromWishlist = async (
  userDetails,
  course,
  setBookmark,
  setLoading
) => {
  setLoading(true);
  try {
    const response = await APICall(api_config.removefromwishlist, {
      user_id: getID("userId"),
      course_id: course.id,
    });
    if (response?.status?.code === 200) {
      setBookmark(false);
      toast.success("Removed from wishlist!", {
        pauseOnHover: false,
      });
    } else {
      toast.error(
        response?.status?.message || "Failed to remove from wishlist",
        {
          pauseOnHover: false,
        }
      );
    }
  } catch (error) {
    toast.error("An error occurred while removing from wishlist", {
      pauseOnHover: false,
    });
  } finally {
    setLoading(false);
  }
};
