import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./CostManagement.module.css"
import image from "../../../assets/img/Group 1321313995.png"

const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course covers cost management strategies, including budgeting, forecasting, and cost control.",
        "Who Can Do: Ideal for financial analysts, project managers, and business leaders.",
        "Benefits: Learn to manage costs effectively, make informed financial decisions, and increase profitability.",
        "Certification: Upon completion, you will receive a certificate in Cost Management."
      ]
    },
  ];

const CostManagement = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Cost Management</h1>
          <p className={'paddingTop-3'}>
          <b>Cost management</b> is essential for any business, focusing on efficiently allocating financial resources to maximize profitability. It involves planning, monitoring, and controlling costs related to projects, departments, or organizations. Effective cost management helps reduce unnecessary expenses, forecast future financial needs, and supports informed decision-making, contributing to sustainable growth and monetary stability.
          </p>
          <p className={'paddingTop-3'}>
          <b>Our comprehensive cost management training courses</b> are designed to suit a range of expertise levels. <b>"Cost Management Fundamentals"</b> provides a solid foundation in cost estimation, budgeting, and control techniques, ideal for new managers and finance professionals. <b>"Advanced Cost Management Strategies"</b> delves into sophisticated tools and techniques such as Activity-Based Costing (ABC) and strategic cost management, tailored for experienced professionals. <b>"Project Cost Management"</b> focuses on applying cost management principles in project settings, covering topics like Earned Value Management (EVM). <b>"Cost Management for IT Projects"</b> addresses the unique challenges of managing costs in IT projects, including cloud cost management. Lastly, <b>"Cost Reduction and Efficiency Improvement"</b> teaches strategies for streamlining operations and reducing costs without sacrificing quality.  
          </p>  
            
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
      <p  className={'paddingTop-3'}>
      <b>Enrolling in our cost management training courses</b> will significantly benefit your career and organization. You will enhance your decision-making capabilities with a deep understanding of cost management principles, leading to data-driven decisions that improve your organization’s financial outcomes. The training will help you identify and eliminate inefficiencies, boost operational performance, and achieve cost savings. Advanced techniques learned through our courses will give you a competitive edge in your career, while practical, real-world applications ensure immediate relevance to your current role. With flexible learning options, our courses cater to beginners and advanced professionals, investing in your professional growth and your organization's financial success.
      </p>
      </div>
    </div>
    )
}

export default CostManagement;