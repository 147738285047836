import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./ManagedServices.module.css"
import image from "../../../assets/img/Group 1321313995.png"

const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course explores the world of managed IT services, including best practices for service management and operations.",
        "Who Can Do: Suitable for IT professionals, service managers, and business owners.",
        "Benefits: Learn to streamline IT operations, reduce costs, and improve service quality.",
        "Certification: Upon completion, you will receive a certificate in Managed Services."
      ]
    },
  ];

const ManagedServices = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Managed Services</h1>
          <p className={'paddingTop-3'}>
            <b>Managed services</b> involve outsourcing the management and operation of IT systems and business processes to specialized providers. This approach allows organizations to focus on their core activities while ensuring their technology infrastructure is efficiently maintained and supported. cover IT infrastructure management, network operations, cloud services, and application support, offering benefits like improved efficiency, enhanced security, and cost-effective solutions tailored to specific needs.
          </p>
          <p className={'paddingTop-3'}>
          <b>Our training courses</b> in include <b>Introduction to Managed Services</b>, which covers fundamental principles, service models, and SLAs. <b>Network Management and Optimization</b> focuses on performance tuning and network issue resolution. <b>Cloud Services Management</b> delves into cloud architecture, security practices, and cost management. <b>IT Infrastructure Management</b> addresses infrastructure design, maintenance, and disaster recovery. <b>Application Support and Maintenance</b> covers application lifecycle management and performance monitoring. <b>Security and Compliance in Managed Services</b> explores data protection laws, security frameworks, and threat management. <b>Service Management and Customer Relations</b> develops skills in service delivery, issue resolution, and client communication. 
          </p>  
            
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
      <p className={'paddingTop-3'}>
      <b>Our training</b> equips you with specialized skills in managed services, enhancing your career prospects and value to employers. For organizations, it means improved IT resource management, optimized costs, and a proactive approach to service delivery. This training drives growth, innovation, and competitive advantage by maximizing the potential of managed services.
      </p>
      </div>
    </div>
    )
}

export default ManagedServices;