import React from "react";
import Styles from "./SalesTeam.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const SalesTeam = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Sales Team Lease</h1>
          <p className={'paddingTop-3'}>
          We can lease a sales team to organizations by implementing a structured approach to the client's needs. The process begins with a thorough needs assessment, where we evaluate the client’s sales objectives, market challenges, and existing processes. Based on this evaluation, a customized sales strategy is developed, outlining how the leased team will address the client's requirements and enhance sales performance.
          </p>
          <p className={'paddingTop-3'}>
          Following strategy development, we select a team of skilled sales professionals with the expertise relevant to the client's industry. This team may include account executives, sales managers, and lead generation specialists. The consultancy handles the onboarding process, ensuring the team is well-versed in the client’s products, services, and sales processes, facilitating a smooth integration into the client’s organization.
          </p>
          <p className={'paddingTop-3'}>
          Once deployed, the sales team works on-site or remotely, depending on the client's preference. We oversee the team's activities, such as lead generation, client engagement, and deal negotiation, ensuring alignment with the established sales strategy. We also act as a liaison, managing communication and addressing any issues, while tracking performance metrics and making necessary adjustments.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
        
      </div>

      <div className={Styles?.afterBannerContent}>
        <p className={'paddingTop-3'}>
        Ongoing support and optimization are key components of our approach. We provide continuous assistance to the sales team and the client, monitoring effectiveness, conducting regular reviews, and implementing best practices to optimize performance. We also offer flexibility in scaling the team based on the client’s evolving needs, whether it involves increasing resources for major sales initiatives or reducing team size after achieving key milestones. This ensures that the sales team remains effective and adaptable to changing market conditions.
        </p>
      </div>
    </div>
  );
};

export default SalesTeam;
