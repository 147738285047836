import React, { useState } from "react";
import Styles from "./ConsultingForm.module.css";
import Button from "../Elements/Button/Button";
import { APICall, CallAPI } from "../backend/apiConfig";
import { api_config } from "../backend/endpoints";

const ConsultingForm = () => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    service: "",
    message: "",
  });

  const sendServiceRequest = async() => {
    const response = await APICall(api_config.createServiceRequest, userDetails)
    console.log(response)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userDetails);
    sendServiceRequest();
    setUserDetails({
      name: "",
      email: "",
      service: "",
      message: "",
    })
  };

  return (
    <div className={`${Styles.Container}`}>
      <form onSubmit={handleSubmit}>
        {/* <div className={Styles?.Form}> */}
          <input
            type="text"
            name="name"
            value={userDetails.name}
            onChange={handleChange}
            placeholder="Name"
          />
          <input
            type="email"
            name="email"
            value={userDetails.email}
            onChange={handleChange}
            placeholder="Email"
          />
        {/* </div> */}
        <select
          name="service"
          value={userDetails.service}
          onChange={handleChange}
        >
          <option value="">--Please choose an option--</option>
          <option value="CloudOps">CloudOps</option>
          <option value="Digital Transformation">Digital Transformation</option>
          <option value="Reliability">Reliability</option>
          <option value="Cost Engineering">Cost Engineering</option>
          <option value="DevOps">DevOps</option>
          <option value="SysOps">SysOps</option>
        </select>
        <textarea
          name="message"
          value={userDetails.message}
          onChange={handleChange}
          placeholder="Message"
        />
        <p>
          By submitting your information, you agree to our revised{" "}
          <b> Privacy Statement</b>
        </p>
        <Button type="submit" label="Let's Talk"></Button>
      </form>
    </div>
  );
};

export default ConsultingForm;
