import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./Containers.module.css"
import image from "../../../assets/img/Group 1321313995.png"

const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course delves into containerization and Kubernetes, including how to deploy and manage containerized applications.",
        "Who Can Do: Suitable for DevOps engineers, system administrators, and developers.",
        "Benefits: Learn to leverage containers for application deployment, and manage them with Kubernetes.",
        "Certification: Upon completion, you will receive a certificate in Containers & EKS."
      ]
    },
  ];

const Container = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Container & EKS</h1>
          <p className={'paddingTop-3'}>
          <b>Containers</b> are a lightweight and efficient method for packaging applications and their dependencies into a single, runnable unit, ensuring a consistent runtime environment across various platforms. This technology simplifies development and deployment, by encapsulating everything an application needs while sharing the host system's resources to reduce overhead compared to traditional virtual machines. Amazon Elastic Kubernetes Service (EKS) is a managed service that simplifies running Kubernetes on AWS by handling the Kubernetes control plane and offering seamless integration with AWS services. EKS automates the deployment, scaling, and management of containerized applications, supports hybrid cloud environments, and enhances resource utilization and application resilience. 
          </p>
          <p className={'paddingTop-3'}>
          <b>Our training courses</b> cover a broad range of topics to enhance your skills in containerization and Kubernetes. The <b>"Introduction to Containers and Docker"</b> course covers the basics of container technology and orchestration. <b>"Advanced Containerization Techniques"</b> delves into complex concepts such as networking and security. <b>"Kubernetes Essentials"</b> introduces core Kubernetes components, while <b>"Mastering Amazon EKS"</b> focuses on deploying and managing Kubernetes clusters on AWS.  
          </p>  
            
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
      <p>
      Additional courses include <b>"Security Best Practices for Containers and Kubernetes,"</b> <b>"EKS for DevOps Engineers,"</b> and <b>"Hybrid Cloud with EKS,"</b> providing specialized knowledge for securing, automating, and managing hybrid cloud environments.
      </p>
      <p className={'paddingTop-3'}>
      <b>Our training</b> equips you with the practical skills and comprehensive knowledge needed to excel in modern cloud-native environments. By mastering these technologies, you'll enhance your ability to deploy, manage, and scale applications efficiently, making you a valuable asset in any organization adopting cloud technologies. This training will prepare you for advanced roles in the tech industry, opening doors to new career opportunities and enabling you to contribute effectively to cutting-edge projects.
      </p>
      </div>
    </div>
    )
}

export default Container;