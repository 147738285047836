import {
  BrowserRouter as Router,
  Routes,
  Route,
  useFetcher,
} from "react-router-dom";
import Header from "../Header/Header";
import Homepage from "../Homepage/Homepage";
import Footer from "../Footer/Footer";
import Diversification from "../Diversification/Diversification";
import MandA from "../MandA/MandA";

import Revenue from "../RevenueGrowth/Revenue";
import Sales from "../SalesPage/Sales";
import Business from "../BusinessAcceleration/Business";
import AllCourses from "../All Courses/AllCourses";
import BOLogin from "../Login/Login";
import BOSignup from "../Signup/Signup";
import { useContext, useEffect, useState } from "react";
import { AppContext, AppProvider } from "../Context/Context";
import { decrypt, getID } from "../../UtilHelper";
import Wishlist from "../wishlist/wishlist";
import Cart from "../Cart/Cart";
import Paymentsuccessful from "../Payment Successful/Paymentsuccessful";
import NoCoursefound from "../NoCourseFound/NoCoursefound";
import MyCourses from "../MyCourses/MyCourses";
import Ipo from "../Ipo/Ipo";
import VentureCapital from "../VentureCapital/VentureCapital";
import AngelInvestments from "../AngelInvestments/AngelInvestments";
import SpecialAcquisition from "../SPACs/SpecialAcquisition";
import GenAi from "../TrainingOps/GenAi/GenAi";
import CloudComputing from "../TrainingOps/CloudComputing/CloudComputing";
import CICD from "../TrainingOps/CICDDeployment/CICD";
import ManagedServices from "../TrainingOps/ManagedServices/ManagedServices";
import FullStack from "../TrainingOps/FullStack/FullStack";
import Container from "../TrainingOps/Containers & EKS/Containers";
import CostManagement from "../TrainingOps/Cost Management/CostManagement";
import SalesMarketing from "../TrainingOps/Sales & Marketing Training/SalesTraining";
import IPProtection from "../TrainingOps/IP Protection/IPProtection";
import AboutUs from "../AboutUs/AboutUs";
import Profitability from "../Profitability/Profitablity";
import Design from "../TeamLease/DesignTeam/Design";
import Content from "../TeamLease/ContentTeam/Content";
import FullStackTeam from "../TeamLease/FullStack/Fullstack";
import AITeam from "../TeamLease/AITeam/AITeam";
import DevopsTeam from "../TeamLease/Devops/Devops";
import SalesTeam from "../TeamLease/SalesTeam/SalesTeam";
import MarketingTeam from "../TeamLease/MarketingTeam/MarketingTeam";
import CloudOps from "../TechOps/CloudOps/CloudOps";
import DataOps from "../TechOps/DataOps/DataOps";
import DevOps from "../TechOps/DevOps/DevOps";
import AIMLOps from "../TechOps/AI&MlOps/AI&MLOps";
import SRE from "../TechOps/SRE/SRE";
import CostOptimization from "../TechOps/CostOptimization/CostOptimization";
import BusinessOps from "../MainMenu/BusinessOps/BusinessOps";
import FinOps from "../MainMenu/FinOps/FinOps";
import Platform from "../MainMenu/PlatformOps/PlatformOps";
import TeamLease from "../MainMenu/TeamLease/TeamLease";
import TechOps from "../MainMenu/TechOpsmenu/TechOpsMenu";
import TrainingOps from "../MainMenu/TrainingOps/TrainingOps";
import TecDemy from "../PlatformMenu/Tecdemy/Tecdemy";
import Training from "../PlatformMenu/Trainingdemy/Trainingdemy";
import MarComUSA from "../PlatformMenu/Marcom/Marcom";
import MobileApp from "../PlatformMenu/MobileApp/Mobileapp";
import MetaPro from "../PlatformMenu/iMetaPro/iMetapro";
import DataSoftware from "../PlatformMenu/DataSoftware/DataSoftware";
import OutGenAI from "../PlatformMenu/OutgenAI/OutgenAI";
import SolvSoftware from "../PlatformMenu/Solv Software/SolvSoftware";
import PulsePredict from "../PlatformMenu/PulsePredict/PulsePredict";
import OurServices from "../Our Services/OurServices";
import Contactpage from "../ContactPage/Contactpage";

const AppRouter = () => {
  const [token, setToken] = useState("");

  const routerArray = [
    {
      name: "Homepage",
      path: "/",
      element: (
        <>
          <Header />
          <Homepage />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/diversification",
      element: (
        <>
          <Header />
          <Diversification />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/ipo",
      element: (
        <>
          <Header />
          <Ipo />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/venture-capital",
      element: (
        <>
          <Header />
          <VentureCapital />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/angel-investments",
      element: (
        <>
          <Header />
          <AngelInvestments />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/angel-investments",
      element: (
        <>
          <Header />
          <AngelInvestments />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/gen-ai-training",
      element: (
        <>
          <Header />
          <GenAi />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/cloud-computing",
      element: (
        <>
          <Header />
          <CloudComputing />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/managed-services",
      element: (
        <>
          <Header />
          <ManagedServices />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/full-stack",
      element: (
        <>
          <Header />
          <FullStack />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/container-eks",
      element: (
        <>
          <Header />
          <Container />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/managed-services",
      element: (
        <>
          <Header />
          <ManagedServices />
          <Footer />
        </>
      ),
    },

    {
      name: "Diversification",
      path: "/cicd-deployment",
      element: (
        <>
          <Header />
          <CICD />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/cost-management",
      element: (
        <>
          <Header />
          <CostManagement />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/sales-marketing-training",
      element: (
        <>
          <Header />
          <SalesMarketing />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/ip-protection",
      element: (
        <>
          <Header />
          <IPProtection />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/design-team",
      element: (
        <>
          <Header />
          <Design />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/content-team",
      element: (
        <>
          <Header />
          <Content />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/fullstack-team",
      element: (
        <>
          <Header />
          <FullStackTeam />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/ai-team",
      element: (
        <>
          <Header />
          <AITeam />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/devops-team",
      element: (
        <>
          <Header />
          <DevopsTeam />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/sales-team",
      element: (
        <>
          <Header />
          <SalesTeam />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/marketing-team",
      element: (
        <>
          <Header />
          <MarketingTeam/>
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/cloud-ops",
      element: (
        <>
          <Header />
          <CloudOps/>
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/data-ops",
      element: (
        <>
          <Header />
          <DataOps/>
          <Footer />
        </>
      ),
    },

    {
      name: "Diversification",
      path: "/m-a",
      element: (
        <>
          <Header />
          <MandA />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/spac",
      element: (
        <>
          <Header />
          <SpecialAcquisition />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/dev-ops",
      element: (
        <>
          <Header />
          <DevOps />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/ai-ml-ops",
      element: (
        <>
          <Header />
          <AIMLOps />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/sre",
      element: (
        <>
          <Header />
          <SRE />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/cost-optimization",
      element: (
        <>
          <Header />
          <CostOptimization/>
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/tecdemy",
      element: (
        <>
          <Header />
          <TecDemy />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/trainingdemy",
      element: (
        <>
          <Header />
          <Training />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/marcomusa",
      element: (
        <>
          <Header />
          <MarComUSA />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/mobileapp",
      element: (
        <>
          <Header />
          <MobileApp />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/imetapro",
      element: (
        <>
          <Header />
          <MetaPro />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/datasoftwarelab",
      element: (
        <>
          <Header />
          <DataSoftware />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/outgenai",
      element: (
        <>
          <Header />
          <OutGenAI />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/solvsoftware",
      element: (
        <>
          <Header />
          <SolvSoftware />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/pulsepredict",
      element: (
        <>
          <Header />
          <PulsePredict />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/business-ops",
      element: (
        <>
          <Header />
          <BusinessOps/>
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/fin-ops",
      element: (
        <>
          <Header />
          <FinOps/>
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/platform-ops",
      element: (
        <>
          <Header />
          <Platform/>
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/platform-ops",
      element: (
        <>
          <Header />
          <Platform/>
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/teamlease",
      element: (
        <>
          <Header />
          <TeamLease/>
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/tech-ops",
      element: (
        <>
          <Header />
          <TechOps/>
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/training-ops",
      element: (
        <>
          <Header />
          <TrainingOps/>
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/revenue-growth",
      element: (
        <>
          <Header />
          <Revenue />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/sales-automation",
      element: (
        <>
          <Header token={token} />
          <Sales />
          <Footer />
        </>
      ),
    },
    {
      name: "Diversification",
      path: "/business-Transformation",
      element: (
        <>
          <Header />
          <Business />
          <Footer />
        </>
      ),
    },
    {
      name: "Login",
      path: "/login",
      element: (
        <>
          <Header />
          <BOLogin setToken={setToken} />
          <Footer />
        </>
      ),
    },
    {
      name: "Sign up",
      path: "/signup",
      element: (
        <>
          <Header />
          <BOSignup />
          <Footer />
        </>
      ),
    },
    {
      name: "AllCourses",
      path: "/courses",
      element: (
        <>
          <Header />
          <AllCourses />
          <Footer />
        </>
      ),
    },
    {
      name: "wishlist",
      path: "/wishlist",
      element: (
        <>
          <Header />
          <Wishlist />
          <Footer />
        </>
      ),
    },
    {
      name: "cart",
      path: "/cart",
      element: (
        <>
          <Header />
          <Cart />
          <Footer />
        </>
      ),
    },
    {
      name: "paymentsuccess",
      path: "/paymentsuccess",
      element: (
        <>
          <Header />
          <Paymentsuccessful />
          <Footer />
        </>
      ),
    },
    {
      name: "MyCourses",
      path: "/mycourses",
      element: (
        <>
          <Header />
          <MyCourses />
          <Footer />
        </>
      ),
    },
    {
      name: "AboutUs",
      path: "/about-us",
      element: (
        <>
          <Header />
          <AboutUs />
          <Footer />
        </>
      ),
    },
    {
      name: "profitability",
      path: "/profitability",
      element: (
        <>
          <Header />
          <Profitability />
          <Footer />
        </>
      ),
    },
    {
      name: "ourservices",
      path: "/ourservices",
      element: (
        <>
          <Header />
          <OurServices />
          <Footer />
        </>
      ),
    },
    {
      name: "contactpage",
      path: "/contactpage",
      element: (
        <>
          <Header />
          <Contactpage />
          <Footer />
        </>
      ),
    },
  ];

  return (
    <AppProvider>
      <Router>
        <Routes>
          {routerArray.map((items) => {
            return <Route path={items?.path} element={items?.element}></Route>;
          })}
        </Routes>
      </Router>
    </AppProvider>
  );
};

export default AppRouter;
