import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./Marcom.module.css"
import image from "../../../assets/img/Group 1321313995.png"
import Button from '../../Elements/Button/Button';


const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course provides an overview of intellectual property (IP) rights, including patents, trademarks, and copyrights.",
        "Who Can Do: Ideal for entrepreneurs, inventors, and business leaders.",
        "Benefits: Understand IP rights, learn to protect your innovations, and navigate IP laws.",
        "Certification: Upon completion, you will receive a certificate in IP Protection."
      ]
    },
  ];

const MarComUSA = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.afterBannerContent}>
          <h1>Marcom USA</h1>
          <p className={'paddingTop-3'}>
          Marcom is a leading Online Marketing Campaign Platform specializing in Branding, Marketing, Promoting, and Growing businesses. We provide a comprehensive suite of services including Brand Promotion, Lead Generation, Online and Social Media Marketing Strategies, and Web & New Media Services. Our expertise encompasses a range of marketing activities, such as Wave Campaigns for customer servicing, reputation management, and lead harvesting. Our Campaign System integrates Email, SMS, and Social Media campaigns with features like scheduling, sequencing, creative follow-up, and realignment, ensuring streamlined execution and effective results.
          </p>
          <p className={'paddingTop-3'}>
          Our advanced tools, such as the Billion Lead Global Lead Management System, facilitate lead qualification, nurturing, conversion, and appointment booking, tailored to meet the unique needs of both B2B and B2C clients. The Direct Wave campaign management system unifies Internet and social media channels, optimizing campaign performance across platforms like Facebook, Twitter, LinkedIn, and Instagram. With a focus on increasing sales performance and revenue, Marcom's dedicated team of marketing specialists supports organizations in leveraging our products and services to enhance their market presence and achieve business growth.   
          </p>  
      <p className={'paddingTop-3'}>
      Interested? Please click the following link to get started  
      </p>
      <p className={'paddingTop-3'}>
      <a href=''>
      <Button>
        Marcom USA
      </Button>
      </a>
      </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>

    </div>
    )
}

export default MarComUSA;