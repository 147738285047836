import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./CICD.module.css"
import image from "../../../assets/img/Group 1321313995.png"

const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course focuses on Continuous Integration/Continuous Deployment (CI/CD), a crucial practice in modern software development.",
        "Who Can Do: Ideal for software developers, DevOps engineers, and IT project managers.",
        "Benefits: Learn to automate software delivery processes, reduce errors, and increase development speed.",
        "Certification: Upon completion, you will receive a certificate in CI/CD Deployment."
      ]
    },
    
  ];

const CICD = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>CICD Deployment</h1>
          <p className={'paddingTop-3'}><b>Continuous Integration and Continuous Deployment (CI/CD)</b> are key practices in modern software development, automating the integration, testing, and deployment of code changes. CI/CD enhances development efficiency, minimizes manual errors, and accelerates time-to-market by streamlining workflows. This approach ensures higher-quality software, faster releases, and more responsive development cycles.
          </p>
          <p className={'paddingTop-3'}>
          <b>Our training courses</b> cover essential aspects of CI/CD. Start with <b>Introduction to CI/CD</b> to grasp core concepts and pipeline fundamentals. Advanced courses like <b>CI/CD Tools & Platforms</b> cover Jenkins, GitLab CI/CD, and CircleCI, while <b>Pipeline Configuration & Management</b> focuses on design and optimization techniques. <b>Automated Testing & Quality Assurance</b> integrates testing frameworks and quality gates, and <b>Deployment Strategies</b> explores various models and environment management. <b>Infrastructure as Code (IaC)</b> training includes tools like Terraform and Ansible, while <b>Security & Compliance</b> covers best practices for security and regulatory compliance. Additional topics include <b>Continuous Monitoring & Feedback</b>, <b>Advanced CI/CD Concepts</b>, and <b>CI/CD in Cloud Environments</b>. 
          </p>  
            
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
      <p className={'paddingTop-3'}>
      <b>Our training</b> provides hands-on experience with the latest CI/CD tools and practices. By completing these courses, you'll gain the expertise to design, implement, and manage CI/CD pipelines effectively. Whether you’re starting a career in DevOps or looking to refine your skills, our training will prepare you to handle the complexities of modern software development, ensuring faster and more reliable delivery of high-quality software.
      </p>
      </div>
    </div>
    )
}

export default CICD;