import React, { useContext } from 'react'

import Styles from "./Contactpage.module.css"
import ConsultingForm from '../ConsultingForm/ConsultingForm'
import Input from '../Elements/Input/Input'
import Button from '../Elements/Button/Button'
import { AppContext } from '../Context/Context'
import { useNavigate } from 'react-router-dom'

const Contactpage = () => {

  const { context, setContext } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.Contact}>
      <ConsultingForm />
      <div className={Styles?.newsletterCont}>
        <div className={Styles?.newsletter}>
        <h1>Join our newsletter</h1>
        <p>Add your details and you’ll receive our newsletter including what’s happening with us in near future and communications if any.</p>
        <h5 className="marginTop-3">Email Address</h5>
        <Input placeHolder="Enter your email"></Input>
        <div className={Styles?.Buttons}>
        <Button label="join our newsletter" className="marginTop-3"></Button>
        {!!!context?.isLoggedIn ? (
          <>
            <Button
              onClick={() => navigate("/login")}
              label="Login"
              className={`${Styles?.loginbtn} marginTop-3 marginLeft-3`}
              />
          </>
        ) : (
          ""
        )}
        </div>
        <div className={`${Styles.DetailsCont} marginTop-4`}>
          <div className={Styles?.Details}>
              <h5><b>For bookings and reservations:</b></h5>
              <p className="marginTop-2"><b>For Bookings :</b> booking@businessoptima.com</p>
              <p className="marginTop-2"><b>For Sales :</b> sales@businessoptima.com </p>
              <p className="marginTop-2"><b>Contact number :</b> </p>
          </div>
          <div className={Styles?.address}>
              <h5><b>Alternatively Contact us at:</b></h5>
              <p>Business Optima</p>
              <p>Chennai</p>
              <p>Tamil Nadu</p>
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Contactpage