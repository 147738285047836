import React from 'react';
import styles from './Footer.module.css';
import { APP_LOGO } from '../../Config/Config';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../Elements/Input/Input';
import Button from '../Elements/Button/Button';

const Footer = () => {
  const navigate = useNavigate()
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.column}>
          {/* <h4>About Us</h4> */}
          <ul className={`${styles?.optionList} ${styles?.logoContainer}`}>
            <li><Link to={"/"}><img className={styles?.appLogo} src={APP_LOGO} /></Link></li>
            <li>Business Optima Consulting</li>
            <li className={styles?.socialIcons}>
              <a href='https://www.facebook.com/Businessoptimagroup'>
              <i className="fab fa-facebook"></i>
              </a>
              <a href='https://youtube.com/@businessoptima?si=8jwwIOVXJ9YUtl2b'>
              <i className="fab fa-youtube"></i>
              </a>
              <a href='https://www.linkedin.com/company/business-optima-target-consulting/?originalSubdomain=in'>
              <i className="fab fa-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.column}>
          <h5>Services</h5>
          <ul className={styles?.optionList}>
            <li><a onClick={() => navigate("/cloud-ops")}>CloudOps</a></li>
            <li><a onClick={() => navigate("/data-ops")}>DataOps</a></li>
            <li><a onClick={() => navigate("/dev-ops")}>DevOps</a></li>
            <li><a onClick={() => navigate("/ai-ml-ops")}>AI & ML Ops</a></li>
            <li><a onClick={() => navigate("/training-ops")}>Training Ops</a></li>
          </ul>
        </div>
        <div className={styles.column}>
          <h5>Company</h5>
          <ul className={styles?.optionList}>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about-us">About Us</Link></li>
            <li><Link to="/ourservices">Our Services</Link></li>
            <li><Link to="/contactpage">Contact</Link></li>
          </ul>
        </div>
        <div className={styles.column}>
          <h5>Contact</h5>
          <ul className={styles?.optionList}>
            <li><a href="#">sales@businessoptima.com</a></li>
          </ul>
        </div>
      </div>
      <p className={styles.copyright}>© 2024 Business Optima. All rights reserved.</p>
    </footer >
  );
};

export default Footer;