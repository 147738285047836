import * as Yup from "yup";




export const LoginPageValidations = Yup.object().shape({
    bus_email: Yup.string().required("This field is required!").email("Please enter a valid email address!"),
    password: Yup.string().required("This field is required!"),
});

export const SignupValidations = Yup.object().shape({
    first_name: Yup.string().required("This field is required!"),
    bus_email: Yup.string()
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Please enter a valid email address!"
        )
        .required("This field is required!"),
    password: Yup.string()
        .min(8, "Password should be greater than 8 characters!")
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
        .max(16, "Password should be less than 16 characters!")
        .required("This field is required!"),
    confirmPassword: Yup.string()
        .required("This field is required!")
        .oneOf(
            [Yup.ref("password"), null],
            "Confirm password should match with password!"
        ),
});