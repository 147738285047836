import React from "react";
import Styles from "./PaymentSuccessful.module.css";

const Paymentsuccessful = () => {
  return (
    <div className={Styles?.Container}>
      <div className={Styles?.Icon}>
        <i class="far fa-circle-check"></i>
      </div>
      <div className={Styles?.Thank}>Thank You</div>
      <div className={Styles?.Para}>
        Purchase has been completed successfully.
      </div>
    </div>
  );
};

export default Paymentsuccessful;
