import React from "react";
import Styles from "./OurServices.module.css";
import image from "../../assets/img/Group 1321313995.png"

const data1 = [
    {
        "title": "Market Research and Analysis",
        "points": [
            "Identify trends, customer needs, and competitive landscapes.",
            "Analyze demand for specific technologies and services."
        ]
    },
    {
        "title": "Customer-Centric Approach",
        "points": [
            "Understand customer needs and tailor solutions.",
            "Provide excellent service to build long-term relationships."
        ]
    },

];

const data2 = [
    {
        "title": "Diversification and Innovation",
        "points": [
            "Explore new technologies and diversify offerings to cater to various industries and needs."
        ]
    },

    {
        "title": "Strategic Partnerships",
        "points": [
            "Form partnerships with other IT companies and technology providers.",
            "Expand services and reach new markets."
        ]
    },
    {
        "title": "Investment in R&D",
        "points": [
            "Allocate resources to stay ahead in technological advancements.",
            "Develop proprietary solutions for a competitive edge."
        ]
    },
    {
        "title": "Digital Marketing",
        "points": [
            "Enhance brand visibility through content marketing, social media, and SEO."
        ]
    },
    {
        "title": "Global Expansion",
        "points": [
            "Tap into new markets and adapt services to international regulations and cultures."
        ]
    },
    {
        "title": "Cybersecurity Services",
        "points": [
            "Emphasize cybersecurity to address increasing digital threats.",
            "Protect client assets from digital threats."
        ]
    },
    {
        "title": "Employee Training",
        "points": [
            "Continuously train employees on the latest technologies.",
            "Ensure high-quality service through skilled and knowledgeable staff."
        ]
    },
    {
        "title": "Data Analytics",
        "points": [
            "Use data analytics for informed decisions.",
            "Offer data analytics services to clients."
        ]
    },
    {
        "title": "Flexible Pricing",
        "points": [
            "Offer flexible pricing models to fit various budgets and business sizes."
        ]
    }
]


const OurServices = () => {
    return (
        <div className={`${Styles?.Container} marginTop-5`}>
            <div className={Styles?.bannerWrapper}>
                <div className={Styles?.LeftCont}>
                    <h3 className={'paddingTop-3'}>Business Optima</h3>
                    <p >
                        Business Optima is a Boutique Business Consulting Company with specialization in Financial, Technology, Platform, Solution Acceleration, Training & Transformation Services. We manage client partnerships and project teams to drive transformations. Contact us at sales@businessoptima.com for all your business consulting needs, and booking@businessoptima.com for your training needs.. We provide the following services.
                    </p>
                    <p className={'paddingTop-3'}>
                        We also assist in Digital transformation with E-commerce strategies, data analytics and automation. We guide organizations through changes in structure, processes, or culture to support growth objectives. We assist organizations in establishing key performance indicators to measure and track progress towards revenue goals.These services are often tailored to the specific needs and goals of the client, and we may use a combination of these approaches to drive sustainable revenue growth.
                    </p>
                    <ul>
                        <li>Strategy Consulting</li>
                        <li>Financial Consulting</li>
                        <li>Technology Consulting</li>
                        <ul>
                            <li>DevOps</li>
                            <li>CICD</li>
                            <li>SysOps</li>
                            <li>MLOps  - Dockers, Kubernetes, Terraform</li>
                            <li>AI Services</li>
                        </ul>
                        <li>Platform Consulting</li>
                        <ul>
                            <li>Platform Engineering & Quality Engineering Services</li>
                        </ul>
                    </ul>
                    <h3 className={'paddingTop-3'}>Marcom services</h3>
                    <p>
                    Marcom is a leading Online Marketing Campaign Platform specializing in Branding, Marketing, Promoting, and Growing businesses. We provide a comprehensive suite of services including Brand Promotion, Lead Generation, Online and Social Media Marketing Strategies, and Web & New Media Services. Our expertise encompasses a range of marketing activities, such as Wave Campaigns for customer servicing, reputation management, and lead harvesting. Our Campaign System integrates Email, SMS, and Social Media campaigns with features like scheduling, sequencing, creative follow-up, and realignment, ensuring streamlined execution and effective results. We provide the following services.
                    </p>
                    <ul>
                        <li>Billion Lead Global Lead Management System</li>
                        <ul>
                            <li>Lead Directory Lead Generation</li>
                        </ul>
                        <li>Lead Servicing</li>
                        <ul>
                            <li>Lead Qualification</li>
                            <li>Lead Nurturing</li>
                            <li>Lead Conversion</li>
                            <li>Appointment Booking</li>
                        </ul>
                        <li>The Direct Wave campaign management system </li>
                        <ul>
                            <li>Campaign system</li>
                            <ul>
                                <li>Email campaign</li>
                                <li>SMS campaign</li>
                                <li>Social Media campaign</li>
                            </ul>
                            <li>Campaign scheduler and calendaring</li>
                            <li>Campaign sequencing</li>
                            <li>Creative follow up</li>
                            <li>Campaign realignment</li>
                            <li>Campaign feedback system</li>
                            <li>Close confirmation</li>
                        </ul>
                        <li>Platform Consulting</li>
                        <ul>
                            <li>Platform Engineering & Quality Engineering Services</li>
                        </ul>
                    </ul>
                    <h3 className={'paddingTop-3'}>Team Lease (Offshore)</h3>
                    <p>
                    Team leasing is a strategic service offered by us to provide specialized teams or professionals to organizations on a temporary or contract basis. This model enables businesses to access expert talent for specific projects or ongoing functions without committing to permanent hires. TeamLease provides staffing solutions and workforce management across diverse industries. Our services span temporary and permanent staffing, payroll and compliance management, skill development, and comprehensive workforce management. We cater to various sectors, including manufacturing, retail, and IT, focusing on flexible, scalable staffing solutions that meet evolving labor market demands. We provide the following teams. 
                    </p>
                    <ul>
                        <li>Design Team</li>
                        <li>Content Team</li>
                        <li>Fullstack Team</li>
                        <li>AI Team</li>
                        <li>DevOps Team</li>
                        <li>Sales Team</li>
                    </ul>
                    <h3 className={'paddingTop-3'}>Training Service</h3>
                    <p>
                    Training Operations involves a strategic approach to managing and optimizing the development of consultants and staff across various technical and business domains. This includes a thorough needs assessment to identify gaps in skills and performance based on current requirements and strategic objectives. Training programs are then designed to address these needs, encompassing a range of areas from technical skills to industry knowledge. The development of tailored training content, such as workshops, seminars, and online courses, ensures that the programs are relevant and effective.<br></br>
Key areas of Training Services  include
 
                    </p>
                    <ul>
                        <li>Generative AI training</li>
                        <li>Cloud Computing & training </li>
                        <li>CI/CD Deployment training</li>
                        <li>Fullstack Web & Mobile App Development training</li>
                        <li>Container & EKS training</li>
                        <li>Cost Management training</li>
                        <li>Sales & Marketing training</li>
                        <li>IP Protection training</li>
                    </ul>
                    <h3 className={'paddingTop-3'}>MVP Development Services</h3>
                    <p>
                    Minimum Viable Products (MVP) are crucial for testing business ideas and validating concepts with minimal investment. We help businesses develop, launch, and refine their MVPs effectively. The Process has following steps
                    </p>
                    <ul>
                        <li>Problem Definition</li>
                        <li>Ideation & Prototyping</li>
                        <li>Core Features Development</li>
                        <li>Testing & Feedback</li>
                        <li>Launch & Marketing</li>
                        <li>Evaluation & Iteration</li>
                    </ul>
                    {false && <div className={Styles?.pointList}>
                        {data1.map((section, index) => (
                            <div key={index} className={`${Styles.Section} marginTop-5`}>
                                <h4>{`- ${section.title}`}</h4>
                                <ul>
                                    {section.points.map((point, idx) => (
                                        <li key={idx}>{point}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>}


                </div>
                <div className={Styles?.rightCont}>
                    <img src={image} />
                </div>
            </div>

            {false && <div className={Styles?.anotherPoints}>
                <div className={Styles?.pointList2}>
                    {data2.map((section, index) => (
                        <div key={index} className={`${Styles.Section2} ${index === 0 || index === 1 ? "marginTop-4" : "marginTop-5"}`}>
                            <h4>{`- ${section.title}`}</h4>
                            <ul>
                                {section.points.map((point, idx) => (
                                    <li key={idx}>{point}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                <h4 className="paddingTop-3">
                    By adopting this comprehensive approach, IT companies can position
                    themselves for sustained revenue growth, ensuring they remain agile,
                    innovative, and responsive to the evolving needs of the market.
                </h4>
            </div>}


        </div>
    );
};

export default OurServices;
