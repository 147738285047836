import React, { useEffect, useState } from "react";
import Styles from "./Checkout.module.css";
import Input from "../Elements/Input/Input";
import Button from "../Elements/Button/Button";
import CouponInput from "../CouponInput/CouponInput";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { APICall } from "../backend/apiConfig";
import { api_config } from "../backend/endpoints";
import { APP_CURRENCY, APP_CURRENCY_CODE } from "../../Config/Config";
import { useNavigate } from "react-router-dom";
import { getID, removeFromCart } from "../../UtilHelper";

const Checkout = ({ courses, setCartList }) => {
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [error, setError] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    console.log(courses);
  }, []);

  const calculateDiscountedAmount = (totalSellingPrice, percentage) => {
    return (totalSellingPrice * (percentage / 100)).toFixed(2);
  };

  const totalActualPrice = courses.reduce(
    (acc, course) => acc + parseFloat(course.actual_price),
    0
  );
  const totalDiscountedPrice = courses.reduce(
    (acc, course) =>
      acc +
      (course.discounted_price
        ? parseFloat(course.discounted_price)
        : parseFloat(course.actual_price)),
    0
  );

  const totalSellingPrice = totalDiscountedPrice;

  const discountedAmount = calculateDiscountedAmount(
    totalSellingPrice,
    discount
  );
  const finalTotalPrice = Math.max(
    0,
    (totalSellingPrice - discountedAmount).toFixed(2)
  );

  const createOrder = async (data, actions) => {
    const items = Array.isArray(courses)
      ? courses.map((course) => ({
          name: course.course_title,
          unit_amount: {
            currency_code: APP_CURRENCY_CODE,
            value: parseFloat(course.discounted_price || course.actual_price),
          },
          quantity: "1",
        }))
      : [];

    try {
      const response = await APICall(
        api_config.createOrder,
        {
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: APP_CURRENCY_CODE,
                value: parseFloat(finalTotalPrice),
                breakdown: {
                  item_total: {
                    currency_code: APP_CURRENCY_CODE,
                    value: parseFloat(finalTotalPrice),
                  },
                },
              },
              items,
            },
          ],
          application_context: {
            shipping_preference: "NO_SHIPPING",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      const { id } = response;
      console.log(id);
      setOrderId(id);
      if (response.status == "CREATED") {
        console.log("success");
      }
      return response.id;
    } catch (error) {
      console.error("Error creating order:", error);
      throw error;
    }
  };

  const onApprove = async (data, actions) => {
    try {
      const details = await actions.order.capture();
      console.log("Order details from PayPal:", details);

      if (details.status === "COMPLETED") {
        for (const course of courses) {
          try {
            const addCourseResponse = await APICall(api_config.addToPurchase, {
              user_id: getID("userId"),
              course_id: course.id,
              actual_price: course.actual_price,
              bought_price: course.discounted_price,
            });

            if (addCourseResponse.status.code === 200) {
              console.log(`Course ${course.id} added successfully.`);
              {
                courses.map((value, index) => {
                  removeFromCart(value, setLoading);
                });
              }
            } else {
              console.error(
                `Failed to add course ${course.id}:`,
                addCourseResponse
              );
            }
          } catch (courseError) {
            console.error(`Error adding course ${course.id}:`, courseError);
          }
        }
        console.log("Order already captured.");
        navigate("/paymentsuccess");
        return;
      }

      const response = await APICall(
        api_config.captureOrder,
        { orderID: data.orderID },
        { headers: { "Content-Type": "application/json" } }
      );

      console.log("Server response after capturing order:", response);

      if (response.status === 200) {
        for (const course of courses) {
          try {
            const addCourseResponse = await APICall(api_config.addToPurchase, {
              user_id: getID("userId"),
              course_id: course.id,
              actual_price: course.actual_price,
              bought_price: course.discounted_price,
            });

            if (addCourseResponse.status === 200) {
              console.log(`Course ${course.id} added successfully.`);
            } else {
              console.error(
                `Failed to add course ${course.id}:`,
                addCourseResponse
              );
            }
          } catch (courseError) {
            console.error(`Error adding course ${course.id}:`, courseError);
          }
        }
        navigate("/paymentsuccess");
      } else {
        console.error("Failed to capture order on server:", response);
      }
    } catch (error) {
      console.error("Error capturing order:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Request data:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  const handleApplyCoupon = () => {
    if (couponCode === "DISCOUNT10") {
      setDiscount(10);
      setError(false);
      setCouponApplied(true);
    } else {
      setDiscount(0);
      setError(true);
      setCouponApplied(false);
    }
  };

  const handleRemoveCoupon = () => {
    setDiscount(0);
    setCouponCode("");
    setError(false);
    setCouponApplied(false);
  };

  return (
    <div className={`${Styles?.Container}`}>
      <div className={Styles?.Heading}>PRICE DETAILS</div>
      <div className={Styles?.Price}>
        <div className={Styles?.Details}>
          <div>Price ({courses.length}&nbsp;&nbsp;items)</div>
          <div className="Flex">
            {totalDiscountedPrice !== totalActualPrice && (
              <div className="linethrough">
                {APP_CURRENCY}
                {totalActualPrice.toFixed(2)}
              </div>
            )}
            <div>
              {APP_CURRENCY}
              {finalTotalPrice.toFixed(2)}
            </div>
          </div>
        </div>
        {discount ? (
          <div className={Styles?.Details}>
            <div>Coupon Discount :</div>
            <div className={Styles?.Discount}>
              <div>
                -{APP_CURRENCY}
                {discountedAmount}
              </div>
              <a
                href="#"
                className={Styles?.removecoupon}
                onClick={handleRemoveCoupon}
              >
                Remove Coupon
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className={Styles?.Details}>
          <div>Total Amount :</div>
          <div>
            {APP_CURRENCY}
            {finalTotalPrice.toFixed(2)}
          </div>
        </div>
      </div>
      <div className={Styles?.gap}></div>
      {!couponApplied && (
        <CouponInput
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          discount={discount}
          setDiscount={setDiscount}
          error={error}
          setError={setError}
          onApplyCoupon={handleApplyCoupon}
        />
      )}
      {/* <div className={Styles?.gap}></div>
      <Button label="Checkout" className={Styles?.Checkoutbtn} /> */}
      <div className={Styles?.gap}></div>
      <div className={Styles?.Paypalbtn}>
        <PayPalButtons
          fundingSource={window.paypal.FUNDING.PAYPAL}
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApprove(data, actions)}
        />
      </div>
    </div>
  );
};

export default Checkout;
