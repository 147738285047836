import React from "react";
import Styles from "./Fullstack.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const FullStackTeam = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Full stack Team Lease</h1>
          <p className={'paddingTop-3'}>
          We effectively lease a full-stack team to organizations by following a structured approach that addresses the client’s needs. First, we conduct a thorough needs assessment to understand the project's scope, goals, and technology requirements. This helps us to tailor the team’s skills and expertise to match the client’s objectives, whether the project involves front-end design, back-end development, or both.
          </p>
          <p className={'paddingTop-3'}>
          Once the needs are clear, we select a suitable full-stack team, which may include front-end and back-end developers, UX/UI designers, and database experts. They handle the onboarding process, introducing the team to the client’s systems, workflows, and project specifics to ensure a smooth start. The team can work on-site or remotely, depending on the client's preference.
          </p>
          <p className={'paddingTop-3'}>
          Throughout the project, we manage the team’s workflow, ensuring alignment with project goals and maintaining regular communication with the client. They address any issues that arise, track progress, and ensure that work meets deadlines and quality standards. Additionally, we provide ongoing support, incorporating client feedback and making necessary adjustments to keep the project on track.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
        
      </div>

      <div className={Styles?.afterBannerContent}>
        <p className='paddingTop-3'>
        We also offer flexibility to adjust team size and composition based on the project's evolving needs. This flexibility allows the client to scale the team up or down as required, helping manage resources efficiently and adapt to changing project requirements. By providing these services, we ensure that the client receives a skilled, well-managed full-stack team.
        </p>
      </div>
    </div>
  );
};

export default FullStackTeam;
