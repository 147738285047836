import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./SalesTraining.module.css"
import image from "../../../assets/img/Group 1321313995.png"

const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course focuses on sales and marketing strategies, including customer acquisition, retention, and relationship management.",
        "Who Can Do: Suitable for sales professionals, marketing executives, and business owners.",
        "Benefits: Learn to drive sales, build effective marketing campaigns, and foster customer relationships.",
        "Certification: Upon completion, you will receive a certificate in Sales & Marketing Training."
      ]
    },
  ];

const SalesMarketing = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Sales & Marketing Training</h1>
          <p className={'paddingTop-3'}>
          <b>Sales and marketing</b> are fundamental to business success, driving revenue growth and ensuring long-term sustainability. Sales focus on engaging customers directly to close deals and generate income, while marketing creates brand awareness, attracts potential clients, and positions products or services effectively. Together, they form a dynamic engine that fuels business expansion, fosters customer loyalty, and provides a competitive edge. In today’s digital marketplace, employing effective sales and marketing strategies is crucial for differentiating a business, meeting evolving customer needs, and achieving ongoing success. 
          </p>
          <p className={'paddingTop-3'}>
          <b>Our Sales and Marketing training program</b> offers a comprehensive range of courses designed to enhance your skills and knowledge in this competitive field.<b>"Digital Marketing Essentials"</b> covers key areas like SEO, content marketing, and social media strategies. <b>"Advanced Sales Techniques"</b> focuses on consultative selling, objection handling, and closing strategies. <b>"Brand Management and Strategy"</b> explores brand positioning and identity creation. <b>"Market Research and Consumer Behavior"</b> teaches effective research and analysis techniques, while <b>"Social Media Marketing Mastery"</b> delves into platform-specific strategies and content creation. <b>"Lead Generation and Conversion Optimization"</b> provides best practices for generating and converting leads, and <b>"Integrated Marketing Communications"</b> covers creating cohesive marketing strategies. Additionally, <b>"Sales Management and Leadership"</b> develops skills for managing and leading a sales team.  
          </p>  
            
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
      <p  className={'paddingTop-3'}>
      <b>Our training</b> equips you with practical skills and actionable insights that can be immediately applied to your role. You’ll enhance your professional skill set, stay ahead of industry trends, and increase your earning potential. The courses boost confidence in tackling complex challenges and making informed marketing decisions. Moreover, you’ll build a strong professional network, connecting with peers and industry experts. This training is designed to transform your career and empower you to achieve your professional goals in sales and marketing.
      </p>
      </div>
    </div>
    )
}

export default SalesMarketing;