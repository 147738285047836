import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./Tecdemy.module.css"
import image from "../../../assets/img/Group 1321313995.png"
import Button from '../../Elements/Button/Button';


const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course provides an overview of intellectual property (IP) rights, including patents, trademarks, and copyrights.",
        "Who Can Do: Ideal for entrepreneurs, inventors, and business leaders.",
        "Benefits: Understand IP rights, learn to protect your innovations, and navigate IP laws.",
        "Certification: Upon completion, you will receive a certificate in IP Protection."
      ]
    },
  ];

const TecDemy = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.afterBannerContent}>
          <h1>Tecdemy</h1>
          <p className={'paddingTop-3'}>
          Tecdemy is an Online Educational and Job Security platform. Tecdemy Platform offers courses, tutorials, or certifications in various tech fields. Tecdemy provides advanced or specialized education in technology and IT. Tecdemy is a community resource center for tech professionals to learn and collaborate. You register as a Student or Mentor at University and learn or teach the necessary courses. We provide spaces for users to connect, share experiences, and seek advice from peers and professionals.
          </p>
          <p className={'paddingTop-3'}>
          As an online job security platform, we provide tools and resources to help individuals safeguard and enhance their employment situations. You can specifically search for jobs based on location, experience & skill by uploading your resume. We improve job search effectiveness through optimized resumes, targeted job listings, and application tracking.
          </p> 
          <p className={'paddingTop-3'}>
          Interested? Please click the following link to get started: 
      </p>
      <p className={'paddingTop-3'}>
      <a href='https://www.tecdemy.com/'>
      <Button>
        Tecdemy
      </Button>
      </a>
      </p> 
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
        
      </div>
    </div>
    )
}

export default TecDemy;