import styles from "./Diversification.module.css"
const Diversification = () => {
    return (
        <div className={styles?.container}>
            <div className={`${styles?.containerHeader} margin-5`}>
                    <h1>Diversification</h1>
            </div>
        </div>
    )
}

export default Diversification;