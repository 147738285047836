import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./MandA.module.css"
import image from "../../assets/img/Group 1321313995.png"

const MandA = () => {
    return (
        <div className={`${Styles?.Container} marginTop-5`}>
    <div className={Styles?.LeftCont}>
    <h1>Mergers & Acquisitions (M&A)</h1>
          <p className={`paddingTop-3`}>Mergers and Acquisitions (M&A) offer significant opportunities for companies to expand, diversify, and enhance their market position. By acquiring businesses in new markets or regions, companies can quickly enter and establish a foothold without the lengthy organic growth process. M&A also facilitates diversification, allowing firms to spread risk and reduce dependence on a single product or market. Additionally, mergsing with or acquiring other businesses can lead to synergies, operational efficiencies, and cost reductions by combining resources and eliminating redundancies. Overall, M&A supports long-term success by allowing companies to adapt, innovate, and maintain a strong competitive position.</p>
          <p className={`paddingTop-3`}>We start by helping firms create a clear M&A strategy, identify suitable acquisition targets, and assess their fit with the company's goals. This includes conducting market research and evaluating industry trends to find the best opportunities. Once potential targets are identified, we handle the due diligence process, including financial analysis and valuation, to ensure the deal is sound. We also assist in structuring and negotiating the deal terms to meet the company’s objectives. After the acquisition, we support integrating the new company, managing risks, ensuring compliance, and helping with a smooth transition to achieve the desired benefits from the M&A.</p>
    </div>
    <div className={Styles?.rightCont}>
      <img src={image}/>
    </div>
  </div>
    )
}

export default MandA;