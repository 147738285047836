import React from "react";
import Styles from "./Technical.module.css";
import { TECHNICALCARDS } from "../../../Config/Config";
import Card from "../Card/Card";

const Technical = ({reference}) => {
  return (
    <div className={`${Styles?.Container}`} >
      <h1>Technical and Business Goals</h1>
      <div className={Styles?.TecCardCont} ref={reference}>
        {TECHNICALCARDS.map((card, index) => (
          <Card
            className={Styles?.Card}
            TitleCont={Styles?.titleCont}
            iconclass={Styles?.icon}
            key={index}
            image={card.icon}
            title={card.title}
            description={card.description}
            imageClass={Styles?.Image}
          />
        ))}
        {/* {TECHNICALCARDS.map((card, index) => (
          <Card
            className={Styles?.Card}
            iconclass={Styles?.icon}
            TitleCont={Styles?.titleCont}
            key={index}
            icon={card.icon}
            title={card.title}
            description={card.description}
          />
        ))} */}
      </div>
    </div>
  );
};

export default Technical;
