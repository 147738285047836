import React from "react";
import Styles from "./Revenue.module.css";
import image from "../../assets/img/Group 1321313995.png"

const data1 = [
  {
    "title": "Market Research and Analysis",
    "points": [
      "Identify trends, customer needs, and competitive landscapes.",
      "Analyze demand for specific technologies and services."
    ]
  },
  {
    "title": "Customer-Centric Approach",
    "points": [
      "Understand customer needs and tailor solutions.",
      "Provide excellent service to build long-term relationships."
    ]
  },

];

const data2 = [
  {
    "title": "Diversification and Innovation",
    "points": [
      "Explore new technologies and diversify offerings to cater to various industries and needs."
    ]
  },

  {
    "title": "Strategic Partnerships",
    "points": [
      "Form partnerships with other IT companies and technology providers.",
      "Expand services and reach new markets."
    ]
  },
  {
    "title": "Investment in R&D",
    "points": [
      "Allocate resources to stay ahead in technological advancements.",
      "Develop proprietary solutions for a competitive edge."
    ]
  },
  {
    "title": "Digital Marketing",
    "points": [
      "Enhance brand visibility through content marketing, social media, and SEO."
    ]
  },
  {
    "title": "Global Expansion",
    "points": [
      "Tap into new markets and adapt services to international regulations and cultures."
    ]
  },
  {
    "title": "Cybersecurity Services",
    "points": [
      "Emphasize cybersecurity to address increasing digital threats.",
      "Protect client assets from digital threats."
    ]
  },
  {
    "title": "Employee Training",
    "points": [
      "Continuously train employees on the latest technologies.",
      "Ensure high-quality service through skilled and knowledgeable staff."
    ]
  },
  {
    "title": "Data Analytics",
    "points": [
      "Use data analytics for informed decisions.",
      "Offer data analytics services to clients."
    ]
  },
  {
    "title": "Flexible Pricing",
    "points": [
      "Offer flexible pricing models to fit various budgets and business sizes."
    ]
  }
]


const Revenue = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Revenue Growth</h1>
          <p className={'paddingTop-3'}>
          Our focus on revenue growth typically offers a range of services aimed at boosting a client's sales, optimizing their revenue streams, and improving overall financial performance. We help you research the market and tailor a strategy based on competitive analysis. We are focused on increasing the efficiency of the sales process by implementing a CRM system and improving data management by providing proper training.
          </p>
          <p className={'paddingTop-3'}>
          We assist you in increasing your revenue by developing a pricing model and strategy & identifying strategic partnerships to drive growth. We help in Customer experience enhancement with Loyalty programs and by collecting feedback from customers. Financial performance can be improved by cost management and profitability analysis.
          </p>
          <p className={'paddingTop-3'}>
          We also assist in Digital transformation with E-commerce strategies, data analytics, and automation. We guide organizations through changes in structure, processes, or culture to support growth objectives. We assist organizations in establishing key performance indicators to measure and track progress toward revenue goals. These services are often tailored to the specific needs and goals of the client, and we may use a combination of these approaches to drive sustainable revenue growth.

          </p>
          {false && <div className={Styles?.pointList}>
            {data1.map((section, index) => (
              <div key={index} className={`${Styles.Section} marginTop-5`}>
                <h4>{`- ${section.title}`}</h4>
                <ul>
                  {section.points.map((point, idx) => (
                    <li key={idx}>{point}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>}


        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>

      {false && <div className={Styles?.anotherPoints}>
        <div className={Styles?.pointList2}>
          {data2.map((section, index) => (
            <div key={index} className={`${Styles.Section2} ${index === 0 || index === 1 ? "marginTop-4" : "marginTop-5"}`}>
              <h4>{`- ${section.title}`}</h4>
              <ul>
                {section.points.map((point, idx) => (
                  <li key={idx}>{point}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <h4 className="paddingTop-3">
          By adopting this comprehensive approach, IT companies can position
          themselves for sustained revenue growth, ensuring they remain agile,
          innovative, and responsive to the evolving needs of the market.
        </h4>
      </div>}


    </div>
  );
};

export default Revenue;
