import React, { useEffect, useState } from "react";
import CourseCard from "../Elements/CourseCard/CourseCard";
import Styles from "./cart.module.css";
import { APICall } from "../backend/apiConfig";
import { api_config } from "../backend/endpoints";
import { toast } from "react-toastify";
import { ShimmerPostList } from "react-shimmer-effects";
import { getID } from "../../UtilHelper";
import Checkout from "../Checkout/Checkout";
import NoCoursefound from "../NoCourseFound/NoCoursefound";

const Cart = () => {
  const [cartlist, setCartList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [filteredCartList, setFilteredCartList] = useState([]);

  useEffect(() => {
    try {
      const userId = getID("userId");
      if (userId) {
        const userDetails = Number(userId);
        setUserDetails(userDetails);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    // Filter out the sections from the cartlist
    const updatedCartList = cartlist.map(({ sections, ...rest }) => rest);
    console.log(updatedCartList);
    setFilteredCartList(updatedCartList);
  }, [cartlist]);

  const fetchCartList = () => {
    try {
      APICall(api_config.getcartlist, { user_id: getID("userId") }).then(
        (res) => {
          console.log(res);
          if (res?.status.code === 200) {
            const { data } = res.session.token;
            if (data) {
              setCartList(data);
              const updatedCartList = data.map(({ sections, ...rest }) => rest);
              setFilteredCartList(updatedCartList);
              setLoading(false);
            }
            return;
          }
          toast.error(res?.status?.message, {
            pauseOnHover: false,
          });
          setLoading(false);
        }
      );
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCartList();
  }, []);

  const handleRemoveFromCart = (courseId) => {
    setCartList((prevList) => {
      const updatedList = prevList.filter((item) => item.id !== courseId);
      setFilteredCartList(updatedList.map(({ sections, ...rest }) => rest));
      return updatedList;
    });
  };

  return (
    <div>
      {loading ? (
        <div className={`${Styles?.MainContainer}`}>
          <div className={Styles?.shimmerwrapper}>
            <ShimmerPostList
              postStyle="STYLE_FOUR"
              card
              col={3}
              row={2}
              imageWidth={500}
              gap={30}
            />
          </div>
        </div>
      ) : cartlist.length > 0 ? (
        <>
          <div className={`${Styles?.MainContainer}`}>
            <div className={Styles?.Container}>
              {cartlist.map((value, index) => {
                const { userDetails } = value;
                return (
                  <CourseCard
                    key={index}
                    imageUrl={value?.thumbnail}
                    title={value.course_title}
                    course={value}
                    duration="30m"
                    author={`${userDetails?.first_name || ""} ${
                      userDetails?.last_name || ""
                    }`}
                    price={value.actual_price}
                    profile={userDetails?.profile_url}
                    onRemoveFromCart={() => handleRemoveFromCart(value.id)}
                  />
                );
              })}
            </div>
            <Checkout courses={filteredCartList} setCartList={setCartList} />
          </div>
        </>
      ) : (
        <NoCoursefound />
      )}
    </div>
  );
};

export default Cart;
