import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./OutgenAI.module.css"
import image from "../../../assets/img/Group 1321313995.png"
import Button from '../../Elements/Button/Button';


const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course provides an overview of intellectual property (IP) rights, including patents, trademarks, and copyrights.",
        "Who Can Do: Ideal for entrepreneurs, inventors, and business leaders.",
        "Benefits: Understand IP rights, learn to protect your innovations, and navigate IP laws.",
        "Certification: Upon completion, you will receive a certificate in IP Protection."
      ]
    },
  ];

const OutGenAI = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.afterBannerContent}>
          <h1>Outgen AI</h1>
          <p className={'paddingTop-3'}>
          OutGen AI is a pioneering Generative AI Platform company that transforms programs into SMT formulas using bit-vector arithmetic. Leveraging advanced universal hashing techniques, OutGen AI produces a diverse array of outputs, driving innovation across industries. This platform not only simplifies complex computational processes but also empowers businesses to explore new frontiers in automation and digital transformation. 
          </p>
          <p className={'paddingTop-3'}>
          In today's fast-paced world, generative AI is advancing, revolutionizing how businesses operate. OutGen AI is at the forefront of this transformation, enabling organizations to harness the power of AI for automation and optimization. As the technology evolves, OutGen AI helps businesses stay ahead, ensuring they are not just keeping up but leading the way in a gen-AI-driven future. With OutGen AI, companies can unlock new opportunities, optimize operations, and create significant value in an increasingly AI-centric economy.   
          </p>  
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
    </div>
    )
}

export default OutGenAI;