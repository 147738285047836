import React from "react";
import Styles from "./Sales.module.css";
import image from "../../assets/img/Group 1321313995.png"

const data = [
  {
    "title": "CRM Alignment",
    "points": [
      "Set up a system to organize and manage customer data and interactions."
    ]
  },
  {
    "title": "Lead Generation",
    "points": [
      "Use automation to find and qualify leads.",
      "Personalize communication with leads."
    ]
  },

];

const data2 = [
  {
    "title": "Automated Communication",
    "points": [
      "Maintain contact with customers through automated emails.",
      "Track campaign performance."
    ]
  },
  {
    "title": "Sales Enablement Platforms",
    "points": [
      "Provide sales teams with tools for content management, training, and analytics."
    ]
  },
  {
    "title": "Workflow Automation",
    "points": [
      "Automate repetitive tasks like data entry and reminders."
    ]
  },
  {
    "title": "Chatbots and AI Assistants",
    "points": [
      "Engage with website visitors and qualify leads using chatbots and AI."
    ]
  },
  {
    "title": "Analytics and Reporting",
    "points": [
      "Track sales performance and customer behavior using analytics tools."
    ]
  },
  {
    "title": "Document Management",
    "points": [
      "Create and manage sales documents efficiently with automation tools."
    ]
  },
  {
    "title": "Sales Forecasting",
    "points": [
      "Predict sales trends using analytics platforms."
    ]
  },
  {
    "title": "Integration",
    "points": [
      "Ensure seamless integration with other systems like ERP or finance."
    ]
  }
]

const Sales = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerContainer}>
        <div className={Styles?.LeftCont}>
          <h1>Sales Automation</h1>
          <p className='paddingTop-3'>
          Sales automation for IT services involves implementing various tools and strategies to streamline sales processes, enhance efficiency, and improve overall sales performance. One key area is lead generation and management, where automation can be achieved through tools like forms and chatbots that capture and qualify leads from multiple sources such as websites, social media, and webinars. Lead scoring systems further assist by automatically prioritizing leads based on criteria such as engagement level, company size, and industry.
          </p>
          <p className='paddingTop-3'>
          Customer relationship management (CRM) is another critical area, with automation facilitating the tracking of customer interactions, management of sales pipelines, and storage of client information. Automated follow-ups, including reminders and emails based on lead activity and sales stages, also play a significant role. Sales process automation extends to workflow automation, which handles routine tasks like sending proposals, updating records, and scheduling meetings. Sales funnel management automates the movement of leads through various stages based on predefined rules and triggers.
          </p>

        </div>
        <div className={Styles?.rightCont}>
        <img src={image} />
      </div>
      </div>
      <div className={Styles?.afterBannerContent}>
        <p className='paddingTop-3'>
        Marketing and sales alignment is enhanced through automated campaigns that nurture leads and guide them through the sales funnel based on their behavior and engagement while leading nurturing workflows include automated email sequences and content delivery. Customer engagement and support are improved by an AI-driven chatbot that handles initial inquiries, schedules meetings and provides basic information, automated ticketing systems, and a support chatbot for efficient issue resolution.
        </p>
      </div>

     
    </div>
  );
};

export default Sales;
