import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./PulsePredict.module.css"
import image from "../../../assets/img/Group 1321313995.png"
import Button from '../../Elements/Button/Button';


const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course provides an overview of intellectual property (IP) rights, including patents, trademarks, and copyrights.",
        "Who Can Do: Ideal for entrepreneurs, inventors, and business leaders.",
        "Benefits: Understand IP rights, learn to protect your innovations, and navigate IP laws.",
        "Certification: Upon completion, you will receive a certificate in IP Protection."
      ]
    },
  ];

const PulsePredict = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.afterBannerContent}>
          <h1>Pulse Predict</h1>
          <p className={'paddingTop-3'}>
          In an era where staying ahead of the curve is paramount, "Pulse Predict" serves as a cutting-edge predictive analytics service designed to empower businesses with foresight into future trends and challenges. By leveraging advanced algorithms and machine learning, Pulse Predict continuously monitors key performance indicators (KPIs) and vital data points, offering real-time insights that enable companies to anticipate market shifts, optimize operations, and make strategic decisions with confidence. This proactive approach not only helps businesses stay resilient in a rapidly evolving landscape but also drives sustainable growth and strategic agility, ensuring they remain competitive and future-ready.
          </p>
          <p className={'paddingTop-3'}>
          With the increasing complexity of global markets, Pulse Predict provides businesses with a critical advantage, turning data into actionable intelligence. By identifying potential risks and growth opportunities before they arise, this service allows organizations to implement data-driven strategies across areas like market research, product development, and customer engagement. As businesses navigate unprecedented changes, Pulse Predict stands out as a pivotal tool for fostering innovation, enhancing decision-making, and ensuring long-term success in a dynamic business environment.
          </p>  
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
    </div>
    )
}

export default PulsePredict;