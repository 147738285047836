import React from "react";
import Styles from "./SRE.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const SRE = () => {
    return (
        <div className={`${Styles?.Container} marginTop-5`}>
            <div className={Styles?.bannerWrapper}>
                <div className={Styles?.LeftCont}>
                    <h1>SRE</h1>
                    <p className={'paddingTop-3'}>
                    Site Reliability Engineering (SRE) is a practice that incorporates software engineering principles into operations to create scalable and highly reliable software systems. SRE teams are responsible for maintaining the availability and performance of services by balancing the demands of feature development with system reliability. By using automation, proactive monitoring, and incident response, SRE helps to ensure that systems are resilient and can recover quickly from failures, enabling continuous improvement in service delivery.
                    </p>
                    <p className={'paddingTop-3'}>
                    We implement SRE by integrating it into our client’s existing processes, focusing on improving system reliability and performance through automation and proactive monitoring. We help organizations adopt SRE practices that align with their specific needs, ensuring that their systems can handle growth and unexpected issues without compromising quality. Our approach includes implementing automated systems to reduce human error, establishing robust incident response protocols, and continuously refining processes to keep up with evolving business requirements.
                    </p>
                    <p className={'paddingTop-3'}>
                    We also emphasize the importance of collaboration between development and operations teams, ensuring that reliability is a shared responsibility across the organization. By fostering a culture of continuous improvement and leveraging data-driven insights, we help our clients build systems that not only meet current demands but are also prepared for future challenges. Our SRE implementations drive greater efficiency, reduce downtime, and support long-term business growth.
                    </p>
                </div>
                <div className={Styles?.rightCont}>
                    <img src={image} />
                </div>
            </div>
        </div>
    );
};

export default SRE;
