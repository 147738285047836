import React from "react";
import Styles from "./DevOps.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const DevOps = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>DevOps</h1>
          <p className={'paddingTop-3'}>
          DevOps is a collaborative approach that integrates development and operations teams to enhance the efficiency and quality of software delivery. By emphasizing automation, continuous integration, and continuous delivery (CI/CD), DevOps aims to streamline workflows, reduce deployment times, and foster a culture of continuous improvement. This methodology supports rapid adaptation to market demands and encourages a seamless flow of communication between teams.
          </p>
          <p className={'paddingTop-3'}>
          We specialize in implementing DevOps strategies tailored to your needs. Our services include automating deployment pipelines, integrating CI/CD processes, and deploying infrastructure as code (IaC) to boost scalability. We focus on enhancing collaboration between development and operations teams, optimizing your workflow with effective monitoring and logging solutions. Additionally, we provide training to ensure your teams are equipped to manage and sustain DevOps practices effectively
          </p>
          <p className={'paddingTop-3'}>
          Adopting our DevOps solutions results in accelerated release cycles and improved software quality. By automating and streamlining processes, we help you achieve faster time-to-market and reduce manual errors. Our approach ensures your organization can quickly adapt to changing requirements, enhancing overall operational efficiency and supporting your growth objectives with a more agile and responsive development environment.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
    </div>
  );
};

export default DevOps;
