import { useState } from "react";
import Styles from "./Input.module.css"
const Input = (props) => {
    const [view, setView] = useState(false);
    const {
        type = "text",
        label = "",
        className = "",
        parentClass = "",
        placeHolder = "",
        onChange,
        ref,
        error = "",
        fieldName = "",
        touched = false,
        onBlur
    } = props;
    return <div className={`${Styles?.parentContainer} ${parentClass}`}>
        {!!label && <label>{label}</label>}
        <input
            type={view ? "text" : type}
            className={`form-control paddingLeftRight-3 ${Styles?.inputClass} ${className}`}
            placeholder={placeHolder}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            name={fieldName}
        />
        {type === "password" ? view ?
            <i onClick={() => setView(!view)} className={`far fa-eye ${Styles?.viewPassword}`}></i> :
            <i onClick={() => setView(!view)} className={`far fa-eye-slash ${Styles?.viewPassword}`}></i> :
            ""}
        {!!error && !!touched && < p className="fieldError fadeInUp">{error}</p>
        }
    </div >

}
export default Input;