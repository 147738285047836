import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Styles from "./CourseCard.module.css";
import Button from "../Button/Button";
import StarRating from "../StarRating/StarRating";
import { placeHolderImage } from "../../../Config/Config";
import {
  addToCart,
  addToWishlist,
  decrypt,
  getID,
  removeFromCart,
  removeFromWishlist,
} from "../../../UtilHelper";
import { Link } from "react-router-dom";
import { BOLoading } from "..";

const CourseCard = (props) => {
  const {
    imageUrl,
    title,
    duration,
    author,
    price,
    onViewCart,
    profile,
    course,
    onRemoveFromCart,
    onRemoveFromWishlist,
  } = props;
  const [bookmark, setBookmark] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [cart, setCart] = useState(false);

  const location = useLocation();

  useEffect(() => {
    try {
      if (getID("userDetails")) {
        const userDetails = decrypt(getID("userDetails"));

        setUserDetails(userDetails);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleWishlistClick = () => {
    if (location.pathname === "/wishlist") {
      removeFromWishlist(userDetails, course, setBookmark, setLoading);
      onRemoveFromWishlist(course.id);
    } else {
      addToWishlist(userDetails, course, setBookmark, setLoading);
    }
  };

  const handleCartClick = () => {
    if (location.pathname === "/cart") {
      onRemoveFromCart(course.id);
      removeFromCart(course, setCartLoading);
    }
    if (location.pathname === "/mycourses") {
    } else if (
      location.pathname === "/wishlist" ||
      location.pathname === "/courses"
    ) {
      addToCart(
        userDetails,
        course,
        course.actual_price,
        setCart,
        setCartLoading
      );
    }
  };

  useEffect(() => {
    console.log(course);
  }, []);

  return (
    <div className={Styles?.courseCard}>
      <div className={Styles?.imageContainer}>
        {location.pathname === "/mycourses" ? (
          ""
        ) : (
          <div className={Styles?.wishlist} onClick={handleWishlistClick}>
            {(loading && location.pathname == "/courses") ||
            (loading && location.pathname == "/cart") ? (
              <BOLoading />
            ) : (
              <i className="far fa-bookmark"></i>
            )}
          </div>
        )}
        <img src={imageUrl} alt={title} className={Styles?.courseImage} />
      </div>
      <div className={Styles?.courseDetails}>
        <StarRating totalStars={6} />

        <h3 className={Styles?.courseTitle}>{title}</h3>
        <div className={Styles?.ProfileBody}>
          <span className={Styles.Profile}>
            <i className="far fa-user"></i>
            <span>0</span>
          </span>
          <span className={Styles?.duration}>
            {" "}
            <i className="far fa-clock"></i>
            {duration}
          </span>
        </div>
        <div className={Styles?.additionalInfo}>
          <div className={Styles?.AvatarContainer}>
            <img
              src={placeHolderImage}
              alt={title}
              className={Styles?.Avatar}
            />
          </div>
          <span className={Styles?.author}>
            By <b>{author}</b>
          </span>
        </div>
      </div>
      <div className={Styles?.footer}>
        <span className={Styles?.price}>${price}</span>
        <div className={Styles?.Buttons}>
          {course.isPurchased === true ? (
            <Button className={Styles?.viewCartButton}>
              <p>View Course</p>
            </Button>
          ) : (
            <Button
              onClick={handleCartClick}
              className={Styles?.viewCartButton}
            >
              <p>
                {location.pathname === "/cart"
                  ? "Remove from cart"
                  : location.pathname === "/mycourses"
                  ? "View Course"
                  : "Add to cart"}
              </p>
              {(cartLoading && location.pathname == "/courses") ||
              (cartLoading && location.pathname == "/wishlist") ? (
                <BOLoading />
              ) : (
                ""
              )}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
