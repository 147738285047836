import React from "react";
import Styles from "./CouponInput.module.css";
import Input from "../Elements/Input/Input";
import Button from "../Elements/Button/Button";

const CouponInput = ({
  couponCode,
  setCouponCode,
  discount,
  setDiscount,
  error,
  setError,
  onApplyCoupon,
}) => {
  // const handleApplyCoupon = () => {
  //   if (couponCode === "DISCOUNT10") {
  //     setDiscount(10);
  //     setError(false);
  //   } else {
  //     setDiscount(0);
  //     setError(true);
  //   }
  //   onApplyCoupon();
  // };

  return (
    <div className={Styles?.Container}>
      <div>Enter Coupon :</div>
      <div className={Styles?.Coupon}>
        <div>
          <Input
            type="text"
            placeHolder="Enter coupon code"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
        </div>
        <Button onClick={onApplyCoupon} className={Styles?.couponbtn}>
          Apply Coupon
        </Button>
      </div>
      {error ? <div className={Styles?.Error}>Invalid Coupon code</div> : ""}
    </div>
  );
};

export default CouponInput;
