import React, { useEffect, useState } from "react";
import CourseCard from "../Elements/CourseCard/CourseCard";
import Image from "../../assets/img/Learn-DevOps-in-90-Min.jpg";
import cloudfoundation from "../../assets/img/Copy-of-Learn-DevOps-in-90-Min.jpg";
import AIandCloud from "../../assets/img/Copy-of-Learn-DevOps-in-90-Min-2.jpg";
import AIandCognitive from "../../assets/img/Copy-of-Learn-DevOps-in-90-Min-4.jpg";
import AIResearch from "../../assets/img/Copy-of-Learn-DevOps-in-90-Min-5.jpg";
import AWSCloudFormmation from "../../assets/img/Copy-of-Learn-DevOps-in-90-Min-9.jpg";
import Architecting from "../../assets/img/Copy-of-Learn-DevOps-in-90-Min-10.jpg";
import Styles from "./wishlist.module.css";
import { APICall, CallAPI } from "../backend/apiConfig";
import { api_config } from "../backend/endpoints";
import { toast } from "react-toastify";
import { ShimmerPostList } from "react-shimmer-effects";
import { NavLink } from "react-router-dom";
import { decrypt, getID } from "../../UtilHelper";
import NoCoursefound from "../NoCourseFound/NoCoursefound";

const Wishlist = () => {
  const [wishList, setWishList] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchWishList = () => {
    try {
      CallAPI(api_config.getwishlist, { user_id: getID("userId") }).then(
        (res) => {
          console.log(res);
          if (res?.status.code === 200) {
            const { data } = res.session.token;
            if (data) {
              setWishList(data);
              setLoading(false);
            }
            return;
          }
          toast.error(res?.status?.message, {
            pauseOnHover: false,
          });
          setLoading(false);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleremovefromwishlist = (courseId) => {
    setWishList((prev) => prev.filter((item) => item.id !== courseId));
  };

  useEffect(() => {
    fetchWishList();
  }, []);
  return (
    <div className={Styles?.MainContainer}>
      {loading ? (
        <div className={Styles?.shimmerwrapper}>
          <ShimmerPostList
            postStyle="STYLE_FOUR"
            card
            col={3}
            row={2}
            imageWidth={500}
            gap={30}
          />
        </div>
      ) : wishList.length > 0 ? (
        <div className={Styles?.Container}>
          {wishList.map((value, index) => {
            console.log(value);
            const { userDetails } = value;
            return (
              <CourseCard
                key={index}
                course={value}
                imageUrl={value?.thumbnail}
                title={value.course_title}
                duration={value.video_length}
                author={`${userDetails?.first_name || ""} ${
                  userDetails?.last_name || ""
                }`}
                price={value.actual_price}
                profile={userDetails?.profile_url}
                onRemoveFromWishlist={handleremovefromwishlist}
              />
            );
          })}
        </div>
      ) : (
        <NoCoursefound />
      )}
    </div>
  );
};

export default Wishlist;
