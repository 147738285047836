import { MDBIcon } from 'mdb-react-ui-kit';
import Styles from "./CloudComputing.module.css"
import image from "../../../assets/img/Group 1321313995.png"

const data = [
    {
      "title": "Market Research and Analysis",
      "points": [
        "Brief: This course covers the basics of cloud computing, including infrastructure, services, storage, and networking.",
        "Who Can Do: Suitable for IT professionals, business leaders, and anyone interested in leveraging cloud technology.",
        "Benefits: Understand the benefits and challenges of cloud computing, and learn to implement and manage cloud resources.",
        "Certification: Upon completion, you will receive a certificate in Cloud Computing."
      ]
    },
    
  ];

const CloudComputing = () => {
    return (
      <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Cloud computing</h1>
          <p className={'paddingTop-3'}>Cloud computing is transforming how businesses operate by offering scalable computing services over the internet, including servers, storage, databases, and software. This model enables companies to innovate, increase efficiency, and reduce costs by only paying for the resources they use. Cloud computing enhances productivity and performance through faster deployment and access to advanced technologies, making it essential for modern businesses.
          </p>
          <p className={'paddingTop-3'}>
          <b>Our training courses</b> cover a broad spectrum of cloud computing topics. Begin with the <b>Introduction to Cloud Computing</b> to learn about core principles and key platforms. Advanced courses like <b>Google Virtual Private Cloud (VPC)</b> and <b>Cloud Licensing Models & Risk Mitigation</b> delve into network design, pricing factors, and risk management. <b>Cloud Native Applications & Data Analytics</b> focuses on building scalable applications and analyzing big data, while <b>Google Kubernetes Engine (GKE)</b> and <b>Google Cloud Resource Manager</b> offer expertise in container management and resource organization. Additional specialized courses include <b>Monitoring Azure Services</b>, <b>Cloud Migration Strategies</b>, and <b>Managing Google Compute Engine</b>, among others, covering comprehensive cloud solutions and management strategies.
          </p>  
            
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
      </div>
      <div className={Styles?.afterBannerContent}>
      <p className={'paddingTop-3'}>
      <b>Our training</b> equips you with practical skills and up-to-date knowledge in cloud technologies. You’ll learn to manage cloud infrastructure efficiently, optimize costs, and apply advanced cloud features to drive business growth. Whether you’re starting a career or enhancing your current skills, our courses prepare you for the evolving landscape of cloud computing.
      </p>
      </div>
    </div>
    )
}

export default CloudComputing;