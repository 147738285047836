import React from "react";
import Styles from "./MarketingTeam.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const MarketingTeam = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Marketing Team Lease</h1>
          <p className={'paddingTop-3'}>
          Leasing a marketing team to organizations through us involves a structured approach to meet the client’s marketing needs. The process begins with assessing the client's requirements, including goals, target audience, and current marketing efforts. Based on this evaluation, we craft a customized marketing strategy that outlines how the leased team will enhance brand visibility and achieve the client's objectives.
          </p>
          <p className={'paddingTop-3'}>
          Next, we select and assemble a team of experienced marketing professionals tailored to the client's industry and goals. This team may include roles such as marketing managers, content creators, and digital marketers. We handle the onboarding process, ensuring the team is well-acquainted with the client's brand and existing marketing efforts, facilitating a smooth integration into the client’s operations.
          </p>
          <p className={'paddingTop-3'}>
          Once deployed, the marketing team works on-site or remotely, depending on the client's preferences. We oversee their activities, which include campaign development, content creation, and market research. We ensure the team adheres to the established marketing strategy, tracks performance metrics, and aligns with the client’s goals. We also manage communication between the client and the team, addressing any issues or adjustments needed during the engagement.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
        
      </div>

      <div className={Styles?.afterBannerContent}>
        <p className={'paddingTop-3'}>
        Throughout the engagement, we provide ongoing support to the marketing team and the client. This includes handling feedback, adjusting strategies as needed, and ensuring the team meets performance expectations. We also monitor campaign effectiveness, conduct regular reviews, and optimize strategies. We offer flexibility to scale the team up or down based on the client’s evolving needs.
        </p>
        <p className={'paddingTop-3'}>
        By following this approach, we ensure the client benefits from a skilled and well-managed marketing team capable of delivering high-quality solutions. This structured support and adaptability help in achieving the client's marketing objectives while addressing their specific needs effectively.
        </p>
      </div>
    </div>
  );
};

export default MarketingTeam;
