import React from "react";
import Styles from "./Content.module.css";
import image from "../../../assets/img/Group 1321313995.png"


const Content = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Content Team Lease</h1>
          <p className={'paddingTop-3'}>
          We effectively lease a content team to organizations by conducting a thorough needs assessment to understand the client’s specific content requirements. We determine the type of content needed, the target audience, and the project goals. We tailor a content solution that aligns with the client’s objectives and select a team of skilled professionals, such as content writers, editors, and SEO specialists, who are well-suited to meet these needs.
          </p>
          <p className={'paddingTop-3'}>
          Once the team is selected, we handle the onboarding process, ensuring content professionals are familiar with the client's industry, brand, and content strategy. The team is then deployed to work on the client’s projects, either on-site or remotely, as per the client’s preference. We manage the workflow, ensuring that content creation aligns with the client’s goals and maintains regular communication to address any issues.
          </p>
          <p className={'paddingTop-3'}>
          Throughout the content creation process, we provide ongoing support to the team, making adjustments and addressing client feedback. This ensures content meets the expected quality and consistency while adhering to deadlines. After completing the content deliverables, we conduct a review to evaluate the effectiveness of the content strategy and gather client feedback for future improvements.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
        
      </div>
        <div className={Styles?.afterBannerContent}>
        <p className='paddingTop-3'>
        Marketing and sales alignment is enhanced through automated campaigns that nurture leads and guide them through the sales funnel based on their behavior and engagement while leading nurturing workflows include automated email sequences and content delivery. Customer engagement and support are improved by AI-driven chatbots that handle initial inquiries, schedule meetings, and provide basic information, along with automated ticketing systems and support chatbots for efficient issue resolution.
        </p>
      </div>

    


    </div>
  );
};

export default Content;
